

<div class="grid r-grid r-grid12-6" style="width:'100%';">

    <div class="g-w12field">
    <div class="header-permisos">
   
        <div class="center " >
            <h3 class="title">Lista de permisos que puede solicitar anular </h3>
    
        </div>
     
    </div>
    </div>



    <div class="g-w12field">
        <p-table [value]="disDaysFinal" dataKey="label">
            <ng-template pTemplate="header">
                <tr>
                    <th>Días</th>
                    <th>Accion Cancelar</th>
                </tr> 
            </ng-template>

            <ng-template pTemplate="body" let-emp let-expanded="expanded" >
              
                <tr *ngIf="emp.length>0">
                    <td style="text-align:left;padding-left:1rem">
                        {{emp}} 
                    </td>

                    <td style="width:60px">
                       <button title='Cancelar Cada' pButton pRipple type="button" icon="fas fa-close" class="p-button-outlined" label="X"  (click)="selectCancelarCadaItem(idSolicitud,solicitudItem,emp)"></button>
                        
                    </td> 
                   

                    
                </tr>
            </ng-template>
        
            
        </p-table>
    </div>
    <br>
            <br>
            <br>
            <br>
            <br>

</div>


<p-toast position='center'></p-toast>

<!--
<p-tabView>
    <p-tabPanel header="Vacaciones">
        <p-scrollPanel [style]="{width: '100%'}">
            <br>
            <div class="example-row">
                <div class="ui-fluid">
                    <p>Disponibilidad:</p>
                    <ul>
                        <li>- 22 días de vacaciones</li>
                        <li>- 5 días por asuntos familiares</li>
                        <li>- 2 días de convenio</li>
                    </ul>
                </div>
                <div class="ui-fluid">
                    <div class="p-field">
                        <label for="tipoPermiso">Tipo de Permiso</label>
                        <p-dropdown id="tipoPermiso" [options]="permisos" [(ngModel)]="selectedPermiso"
                            placeholder="Seleccione" optionLabel="nombre" [showClear]="true"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="example-row">
                <div class="ui-fluid">
                    <div class="p-field">
                        <label for="dateFrom">Fecha desde</label>
                        <p-calendar id="dateFrom" [(ngModel)]="dateFrom" [showIcon]="true" [locale]="es"
                            dateFormat="dd/mm/yy" [touchUI]="true" [readonlyInput]="true"></p-calendar>
                    </div>
                </div>
                <div class="ui-fluid">
                    <div class="p-field">
                        <label for="dateTo">Fecha hasta</label>
                        <p-calendar id="dateTo" [(ngModel)]="dateTo" [showIcon]="true" [locale]="es"
                            dateFormat="dd/mm/yy" [touchUI]="true" [readonlyInput]="true">
                        </p-calendar>
                    </div>
                </div>
            </div>
            <div class="left-content">
                <p-button label="Solicitar" [routerLink]="['/login']" icon="fas fa-paper-plane" iconPos="left">
                </p-button>
            </div>
        </p-scrollPanel>
    </p-tabPanel>
    <p-tabPanel header="Calendario" id="tab-calendar">
        <br>
        <div class="example-row" id="container-summary">
            <div class="list-square">
                <div class="square square-accent"></div>
                Festivos
            </div>
            <div class="list-square">
                <div class="square square-muted"></div>
                Permisos
            </div>
            <div class="list-square">
                <div class="square square-primary"></div>
                Vacaciones
            </div>
            <div class="list-square">
                <div class="square square-red"></div>
                Bajas
            </div>
        </div>
        <br>
        
            <div class="example-row" id="container-clendars">
                <p-calendar *ngFor="let calendario of calendarios" [inline]="true" [locale]="es"
                    [defaultDate]="calendario.month">
                    <ng-template pTemplate="date" let-date>
                        <ng-container
                            *ngIf="calendario.permisosDays.includes(date.day); then permissionDate;else vacationsDate">
                        </ng-container>
                        <ng-template #permissionDate>
                            <span [ngStyle]="{backgroundColor: 'grey'}"
                                style="border-radius:10%; color: #ffffff;  padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                        </ng-template>
                        <ng-template #vacationsDate>
                            <ng-container *ngIf="calendario.vacacionesDays.includes(date.day) ;else festivosDate">
                                <span [ngStyle]="{backgroundColor: '#0e1e45'}"
                                    style="border-radius:10%; color: #ffffff; padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #festivosDate>
                            <ng-container *ngIf="calendario.festivosDays.includes(date.day);else bajasDate">
                                <span [ngStyle]="{backgroundColor: '#6f61fd'}"
                                    style="border-radius:10%; color: #ffffff;  padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #bajasDate>
                            <ng-container *ngIf="calendario.bajasDays.includes(date.day);else normalDate">
                                <span [ngStyle]="{backgroundColor: 'red'}"
                                    style="border-radius:10%; color: #ffffff; padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #normalDate>
                            <span [ngStyle]="{backgroundColor: 'inherit'}"
                                style="border-radius:10%; padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                        </ng-template>

                    </ng-template>
                </p-calendar>
            </div>
    </p-tabPanel>
</p-tabView>
-->