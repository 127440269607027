import { Injectable } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VistasDateService {
  private selectedYear = new Subject<any>();
  private documents:TreeNode[]  
  constructor() { }

  setSelectedYear(selectedYear) {
    this.selectedYear.next(selectedYear);
  }

  clearSelectedYear() {
    this.selectedYear.next();
  }

  getSelectedYear(): Observable<any> {
    return this.selectedYear.asObservable();
  }

  setDocuments(doc:TreeNode[]) {
    this.documents = doc;
    console.log(this.documents)
  }

  getDocuments():TreeNode[] {
    console.log(this.documents)
    return this.documents
  }
}
