<!-- <h3 class="title">Cambiar la contraseña</h3> -->



<div class="grid r-grid r-grid12-6" style="width:'100%';">



    <div class="g-w12field">
        <div class="header-permisos">
       
            <div class="center " >
                <h3 class="title">Cambiar la contraseña</h3>
        
            </div>
         
        </div>
        </div>
   
    <div class="g-w12field">
        <label>Nueva contraseña</label>
        <input pInputText [(ngModel)]="newPassword" styleClass="fecha-hora"/>
    </div>

    <div class="g-w12field">
        <label>Cambiar contraseña</label>
        <input pInputText [(ngModel)]="confirmPassword" styleClass="fecha-hora"/>
    </div>
   

   

    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w1"><p-button label="Cambiar contraseña" (click)="changePassword()" icon="fas fa-paper-plane" iconPos="left"></p-button></div>
        </div>
    </div>



</div>


<p-toast position='center'></p-toast>
