<div class="grid r-grid r-grid12-6 complaint-css" style="width:'100%';">
    <!-- <div class="g-w12field">
        <label for="complaint">Complaint</label>
        <input p-inputText id="complaint" [disabled]="readonly" [(ngModel)]="complaint.solicitud"
            placeholder="Describa la complaint" />
    </div> -->

    <div class="g-w12field">
        <label for="complaintDate">Fecha denuncia</label>
        <p-calendar id="complaintDate" [(ngModel)]="complaint.complaintDate" [showIcon]="true" [locale]="dateConfig"
            [touchUI]="true" [readonlyInput]="true" [disabled]="readonly" dateFormat="dd/mm/yy"></p-calendar>
    </div>

    <div class="g-w12field">
        <label for="complaintPrivacy">Privacidad</label>

        <!-- <p-dropdown id="complaintPrivacy" [options]="privacy"  [(ngModel)]="complaint.complaintPrivacy"
        placeholder="Seleccione" optionLabel="privacy" ></p-dropdown> -->
        <p-dropdown placeholder="Seleccione"  id="complaintPrivacy"  [options]="privacy" [(ngModel)]="complaint.complaintPrivacy"  [showClear]="true"></p-dropdown>

            <!-- <p-dropdown [options]="countries" [(ngModel)]="applicant.country"></p-dropdown>

            <br/><br/>

            <div *ngIf="dataLoaded">
            <p-dropdown [options]="countries" [(ngModel)]="applicant.country"></p-dropdown>
            </div>

            <div *ngIf="!dataLoaded">
            Waiting for data...
            </div>

        <select id="complaintPrivacy" [(ngModel)]="complaint.complaintPrivacy" >
            <option >Seleccione</option>
            <option>Anonymous</option>
            <option>Confidential</option>
            
        </select> -->
    </div>

    <div class="g-w12field">
        <label for="complaintType">Tipo de denuncia</label>
       <p-dropdown placeholder="Seleccione" id="complaintType" [options]="type" [(ngModel)]="complaint.complaintType" [showClear]="true"></p-dropdown>

        
    </div>

   

    

    <div class="g-w12field" *ngIf="readonly">
        <label>Persona a cargo</label>
        <input pInputText id="complaintHandledClientName" [(ngModel)]="complaint.complaintHandledClientName" styleClass="fecha-hora" [readonly]="true"   />
    </div>


    <div class="g-w12field"  *ngIf="readonly">
        <label for="complaintStatus">Estado</label>
       
         
         <p-dropdown placeholder="Seleccione" id="complaintStatus" [readonly]="true"   [options]="status" [(ngModel)]="complaint.complaintStatus" [showClear]="true"></p-dropdown>
    </div>

    <div class="g-w12field">
        <label for="complaintDesc">Descripción</label>
        <textarea [disabled]="readonly" id="complaintDesc" [rows]="5" [cols]="30" pInputTextarea
            [(ngModel)]="complaint.complaintDesc" autoResize="autoResize"></textarea>
    </div>


    <!-- <div class="g-w12field" *ngIf="readonly">
        <label for="complaintDesc">Complaint Comunicación interna</label>
        <textarea [disabled]="readonly" id="complaintCommunication" [rows]="5" [cols]="30" pInputTextarea
            [(ngModel)]="complaint.complaintCommunication" autoResize="autoResize"></textarea>
    </div> -->


    <div class="g-w12field" >
        
            <!-- <p-fileUpload [disabled]="readonly" fileLimit="1" auto="true" multiple="false" id="envioDoc" #envioDoc
                [showUploadButton]="false" chooseLabel="Adjuntar" maxFileSize="50000000"></p-fileUpload> -->

                <p-fileUpload [disabled]="readonly" fileLimit="1" auto="true" multiple="false" id="attachDocument" #attachDocument
                [showUploadButton]="false" chooseLabel="Adjuntar" maxFileSize="50000000"></p-fileUpload>

                <!-- <p-fileUpload mode="basic" #attachDocument id="attachDocument" fileLimit="1" chooseLabel="Adjunto Document"
            customUpload="true"  (onSelect)="onBasicUpload($event , 'file1')"></p-fileUpload> -->
        </div>
    
        <div *ngIf=" complaint.complaintAttachDocument!='' && complaint.complaintAttachDocument!=null" class="g-w12field">
            <a href="#" (click)='cbl.viewIDDoc(complaint.complaintAttachDocument)'>Documento adjunto</a>
        </div>



        <!-- <div class="g-w12field" *ngIf="readonly">
            <label for="complaintDesc">Complaint Comunicación interna</label>
            <textarea [disabled]="readonly" id="complaintCommunication" [rows]="5" [cols]="30" pInputTextarea
                [(ngModel)]="complaint.complaintCommunication" autoResize="autoResize"></textarea>
        </div> -->



        <div class="g-w12field" *ngIf="readonly">
            <label for="complaintDesc"><b>Historial de mensajes : </b></label><hr>
    
    
            <div class="g-w12field" *ngFor="let item of allcomplaintcommunication">
                {{item.communicationDate}} >> {{item.userName}} >> "{{item.complaintCommunication}}" 
                <hr> 
            </div>
    
    
    
        </div>

        <div class="g-w12field" *ngIf="readonly">
            <label for="complaintDesc">Enviar nuevo mensaje</label>
            <textarea  id="complaintCommunication" [rows]="5" [cols]="30" pInputTextarea
                [(ngModel)]="complaint.complaintCommunication" autoResize="autoResize"></textarea>
        </div>

    <!-- <div class="g-w6field">
        <label for="dateFrom">Hora de inicio</label>
        <p-calendar id="dateFrom" [timeOnly]="true" [showTime]="true" [(ngModel)]="complaint.horainicio"
            [showIcon]="true" [locale]="dateConfig" [touchUI]="true" [readonlyInput]="true" [disabled]="readonly"
            dateFormat="hh:mm"></p-calendar>
    </div>

    <div class="g-w6field">
        <label for="dateTo">Hora de fin</label>
        <p-calendar id="dateTo" [timeOnly]="true" [showTime]="true" [(ngModel)]="complaint.horafin" [showIcon]="true"
            [locale]="dateConfig" [touchUI]="true" [readonlyInput]="true" [disabled]="readonly" dateFormat="dd/mm/yy">
        </p-calendar>
    </div> -->

    
    <!-- <div *ngIf="complaint.contestacion!=''" class="g-w12field">
        <label for="observaciones">Observaciones</label>
        <textarea [disabled]="readonly" id="observaciones" [rows]="5" [cols]="30" pInputTextarea
            [(ngModel)]="complaint.contestacion" autoResize="autoResize"></textarea>
    </div> -->
<!-- <div *ngIf="!readonly" style="display: none">
   
        <p-fileUpload [disabled]="readonly" fileLimit="1" auto="true" multiple="false" id="envioDoc" #envioDoc
            [showUploadButton]="false" chooseLabel="Adjuntar" maxFileSize="50000000"></p-fileUpload>
    </div> -->

    <!-- <div *ngIf="readonly && complaint.doc!='' && complaint.doc!=null" class="g-w12field">
        <a href="#" (click)='cbl.viewIDDoc(complaint.doc)'>Documento adjunto</a>
    </div> -->


    <!-- <div class="g-w12field" *ngIf="readonly">

        <h3 class="title" position='center'>Añadir comentarios</h3>
        <div class="g-w12field">
            <label for="comment">Comentarios</label>
            <textarea id="comment" [rows]="3" [cols]="30" pInputTextarea [(ngModel)]="remarkBody.comment"
                autoResize="autoResize"></textarea>
        </div>

        <p-fileUpload mode="basic" #fileUpload name="demo[]" accept=".pdf,.jpg,.jped,.png,.gif" chooseLabel="Adjunto 1"
            customUpload="true" maxFileSize="1000000" (onSelect)="onBasicUpload($event , 'file1')"></p-fileUpload>

        <p-fileUpload mode="basic" #fileUpload2 name="demo[]" accept=".pdf,.jpg,.jped,.png,.gif" chooseLabel="Adjunto 2"
            customUpload="true" maxFileSize="1000000" (onSelect)="onBasicUpload($event , 'file2')"></p-fileUpload>

        <p-fileUpload mode="basic" #fileUpload3 name="demo[]" accept=".pdf,.jpg,.jped,.png,.gif" chooseLabel="Adjunto 3"
            customUpload="true" maxFileSize="1000000" (onSelect)="onBasicUpload($event , 'file3')"></p-fileUpload>

    </div> -->


    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w2">
                <!-- <p-button *ngIf="!readonly" label="Enviar" (click)="solicitar()"></p-button> -->
                <p-button *ngIf="!readonly" label="Enviar" (click)="solicitar()"></p-button>
                <p-button *ngIf="readonly" label="Enviar mensaje" (click)="saveComment()" style="margin-right:10px"></p-button>
                <p-button *ngIf="readonly" label="Guardar" (click)="saveComment()" style="margin-right:10px"></p-button>
                <!-- <p-button *ngIf="readonly" label="Guardar" (click)="saveComment()" style="margin-right:10px"></p-button>
                <p-button *ngIf="readonly" label="Cerrar" (click)="cerrar()"></p-button> -->
            </div>
        </div>
    </div>

    <!--sample remarks cards start-->
    <!-- <div class="g-w12field" *ngIf="readonly">
        <h3 class="title">Historial</h3>
        <p-card class="remark-card" *ngFor="let item of history">
            <span><strong>{{item?.timeStamp || 'NA'}}</strong></span>
            <div>{{item?.comment || 'NA'}}</div>

            <a (click)="showpdf(item.file1)" *ngIf="item.file1">File 1</a> &nbsp; <a *ngIf="item.file2"
                (click)="showpdf(item.file2)">File 2</a> &nbsp; <a (click)="showpdf(item.file3)" *ngIf="item.file3">File
                3</a>
        </p-card>
        <br>
            <br>
            <br>
            <br>
            <br>
    </div> -->
    <!--sample remarks cards end-->

</div>

<p-toast position='center'></p-toast>