<!--
    <div class="box">
    
    <p>{{crono | date: 'HH:mm:ss'}}</p>
    <p-button label="Fichar entrada" (onClick)="ficharEntrada()"></p-button>
</div>
-->

<h3 class="title">Bienvenid@ {{employeeName}}</h3>

<app-fichaje></app-fichaje>


