
<!-- <h3 class="title">Comunicaciones</h3> -->
<div class="header-nominas">
   <div class="center nominas_width" >
        <h3 class="title">Comunicaciones</h3>
    </div>
</div>


<p-scrollPanel [style]="{width: '100%'}">
    <div class="example-column">
        <p-table  [value]="nominas" sortMode="multiple" [rowsPerPageOptions]="[15,10,20,30]" [paginator]="true" [rows]="15"
            [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} de {totalPages}">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="fecha">Fecha<p-sortIcon field="fecha"></p-sortIcon></th>
                    <th>Descripción</th>
                    <th>Estado</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-nomina>
                <tr>
                    <td>{{nomina.fecha}}</td>
                    <td>{{nomina.Descripcion}}</td>
                   <!-- <td *ngIf="nomina.type == 'document'">
                        <i [attr.data-id]="nomina.idDocumento" class="far fa-file-pdf icono enlace" style="cursor: pointer;" (click)="click(nomina.idDocumento)"></i>
                    </td>-->
                    <td *ngIf="nomina.type == 'permios'">
                        {{ nomina.idDocumento}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    

    <div class="example-column">
        <div class="header-nominas">
            <div class="center nominas_width" >
                <h3 class="title">Documentos recibidos</h3>
             </div>
         </div>
        
        <p-table  [value]="documento" sortMode="multiple" [rowsPerPageOptions]="[15,10,20,30]" [paginator]="true" [rows]="15"
            [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} de {totalPages}">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="fecha">Fecha<p-sortIcon field="fecha"></p-sortIcon>
                    </th>
                    <th>Descripción</th>
                    <th>Fichero</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-nomina>
                <tr>
                    <td>{{nomina.fecha}}</td>
                    <td>{{nomina.Descripcion}}</td>
                    <td *ngIf="nomina.type == 'document'">
                        <i [attr.data-id]="nomina.idDocumento" class="far fa-file-pdf icono enlace" style="cursor: pointer;" (click)="click(nomina.idDocumento)"></i>
                    </td>
                  <!--  <td *ngIf="nomina.type == 'permios'">
                        {{ nomina.idDocumento}}
                    </td>-->
                </tr>
            </ng-template>
        </p-table>
        <br>
            <br>
            <br>
            <br>
            <br>
    </div>

</p-scrollPanel>

<!-- <h3 class="title">Documentos recibidos</h3> -->


