<!--<p-scrollPanel [style]="{width: '100%'}">-->
    <div class="example-column">
        <p-calendar [(ngModel)]="dateSelector" [locale]="dateConfig" [showIcon]="true" view="month" dateFormat="mm/yy"
            [yearNavigator]="true" yearRange="2000:2030" [readonlyInput]="true" inputId="monthpicker"></p-calendar>

        <p-table [value]="jornadas" [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5"
            [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} de {totalPages}">
            <ng-template pTemplate="header">
                <tr>
                    <th>Fecha</th>
                    <th>Saldo</th>
                    <th>Observaciones</th>
                    <th width="10%"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-jor>
                <tr>
                    <td>{{jor.fecha}}</td>
                    <td>{{jor.saldo}}</td>
                    <td>{{jor.situacion}} </td>
                    <td>
                        <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-eye" class="p-button-outlined"
                        (click)="selectJornada(jor.fecha)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td>Saldo acumulado:</td>
                    <td>136h 10m</td> 
                    <td colspan="2"></td>
                </tr>
            </ng-template>
        </p-table>
    </div>
<!--</p-scrollPanel>-->

