import { Component, OnInit, ViewChild } from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {ComplaintComponent } from '../complaint/complaint.component';
import {CBLService} from '../servicios/cbl.service'
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { Respuesta } from '../libs/respuesta';
import {formatDate} from '@angular/common';
import {Solicitud,Documento} from '../libs/dataObjects'
import {MessageService} from 'primeng/api';
import {FileUpload} from 'primeng/fileupload';
import { Dropdown } from "primeng/dropdown";

export interface TiposComplaint {
  nombre: string;
  id: string;
}

export class Complaint {
  estado: string;
  fechaInicio: Date;
  fechaFin: Date;
  observaciones: string;
  tipoComplaint: number;
  adjuntos: any[] = [];
  tipo: string;
  motivo: string;
}

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss'],
})
export class ComplaintsComponent implements OnInit {
  @ViewChild('dropDownThing')  dropDownThing: Dropdown;
  tiposComplaints: TiposComplaint[];
  selectedTipoComplaint: TiposComplaint;
  selectedComplaint: Complaint = null;
  tempComplaint: Complaint = new Complaint();
  collapsed: boolean = true;
  yearArray:any[] = [];
  selectedYear:any;
  message = "Documentos del año";
  currentSearchYear:any;
  es: any;
  dateFrom: Date;
  dateTo: Date;
  complaintsTemp =[]
  complaints: Complaint[] = [
    {
      estado: 'Pendiente',
      tipo: 'Asistencia al médico',
      fechaInicio: new Date(),
      fechaFin: null,
      observaciones: 'Se encuentra en estado pendiente en el lugar.',
      tipoComplaint: 1,
      adjuntos: [
        { name: 'Cita previa en clínica estomatológica', size: '30.000' },
      ],
      motivo: '',
    },
    {
      estado: 'Rechazada',
      tipo: 'Olvido de fichaje',
      fechaInicio: new Date(),
      fechaFin: new Date(),
      observaciones: '',
      tipoComplaint: 2,
      adjuntos: [],
      motivo:
        'Falta de documentación acreditativa sobre el motivo de la ausencia',
    },
  ];

  uploadedFiles: any[] = [];

  constructor(public cbl:CBLService,private messageService: MessageService,private httpClient: HttpClient,public dialogService: DialogService) {
    this.tiposComplaints = [
      { nombre: 'Asistencia al médico', id: '1' },
      { nombre: 'Olvido de fichaje', id: '2' },
    ];
  }

  ngOnInit(): void {
    this.createYearPicker();
    this. loadComplaints();

    this.es = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      clear: 'Borrar',
    };
  }

  onUpload(event) {
    for (let file of event.files) {
      this.tempComplaint.adjuntos.push(file);
    }
  }

  onRowSelect(event) {
    this.collapsed = false;
    this.tempComplaint = this.selectedComplaint;
    this.selectedTipoComplaint = this.tiposComplaints[
      this.tempComplaint.tipoComplaint - 1
    ];
  }
  onRowUnselect(event) { 
    this.collapsed = true;
    this.tempComplaint = new Complaint();
    this.selectedComplaint = null;
    this.selectedTipoComplaint = null;
  }

  crearComplaint(event) {
    this.collapsed = true;
  }

  selectComplaint(id){
    const ref = this.dialogService.open(ComplaintComponent, {
      data:{id:id},
      header: 'Detalles de la denuncia',
      width: '70%'
  });

  }

  nueva()
  {
    const ref = this.dialogService.open(ComplaintComponent, {
      data:{id:0},
      header: 'Detalle denuncia',
      width: '70%'
      });

    ref.onClose.subscribe((ret) => {
      this.selectedYear = null
      if(this.dropDownThing != undefined && this.dropDownThing != null)
        this.dropDownThing.updateSelectedOption(null);
      this.loadComplaints();
     
    });
    
  }

  

  async getEmployeeName(id){
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,T.empleado as empleado'
    +'&where=E.idEmpleado='+id+' '
    +'&order=E.idEmpleado DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['empleado'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/ 
    }); 

   return respuesta;
  }
  async getClientName(id){
    let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idCliente,Cliente'
    +'&where=idCliente='+id+' '
    +'&order=idCliente DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['Cliente'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  
async getPersenInChargeName(id){
  let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  +'&fields=idCliente,Cliente,encargadoDenuncias'
  +'&where=idCliente='+id+' '
  +'&order=idCliente DESC';  //&compacto=true

  //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

 // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

  //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
  var respuesta;
  await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
    let resp=r['resp'];
    respuesta=resp[0]['encargadoDenuncias'];
    //this.nominasTemp = r['resp'];
    console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
    /*this.total=0.0;
    this.nominas=this.nominas.map((nom)=>{
       if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
       return nom;
    });
    console.log(this.nominas,this.total);*/
  }); 

 return respuesta;
}









  loadComplaints()
  {
    // let url2='solicitudes?'
    // +'&fields=idsolicitud,solicitud,fechainicio,obs,fechafin,horainicio,horafin,fechas,estado'
    // +'&where=idEmpleado='+this.cbl.id_empleado+" and idTipoPermiso=0"
    // +'&order=fechainicio DESC';

    // let url2='complaint?'
    // +'&fields=idComplaint,idEmpleado,complaintDate,complaintPrivacy,complaintType,complaintAttachDocument,complaintDesc,complaintStatus,complaintCommunication'
    // +'&where=idEmpleado='+this.cbl.id_empleado+" "
    // +'&order=idComplaint DESC'; 

    let url2='complaint?'
    +'&fields=idComplaint,idEmpleado,complaintDate,complaintPrivacy,complaintType,complaintAttachDocument,complaintDesc,complaintStatus,complaintCommunication,complaintHandledClientId'
    +'&where=idEmpleado='+this.cbl.id_empleado+""
    +'&order=idComplaint DESC';

    //+'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado';

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then(async (r)=>{
      console.log(r);
      
      for(let p of r.resp)
      {
          let dias=0;
          // for(let d of p.permisos)
          // {
            // let cd=p.fechas.split(",").length;
            // p['dias']=cd; 
            // if (cd>1)
            //   p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
            // else
            //   p['periodo']=p.fechainicio;

            // if(p.horainicio!=null && p.horafin!=null)
            // {
            //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
            // }
            // if(p.estado!="RECHAZADA") dias+=cd;

           // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

           // p['periodo']=p.complaintDate;
          // }
          // let respEmp=this.getEmployeeName(p.idEmpleado);
          // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
          //  p['dias']=dias;
          //  p['employeeName']=await this.getEmployeeName(p.idEmpleado);

         // let respEmp=this.getEmployeeName(p.idEmpleado);
          //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
           p['dias']=dias;
           p['employeeName']=await this.getEmployeeName(p.idEmpleado);
          if(p.complaintHandledClientId!='0')
          {
            //p['complaintInCharge']=await this.getClientName(p.complaintHandledClientId);
            p['complaintInCharge']=await this.getPersenInChargeName(p.complaintHandledClientId);
          }
          else{
            p['complaintInCharge']=""; 
          }
           


           
           //this.getEmployeeName(p.idEmpleado);
          //r.resp.push(p);
         // r.resp.push(m);
      }
      console.log("sssssssssssssssssssss",r.resp);
      this.complaints=r.resp;
      this.complaintsTemp=r.resp;
      this.searchBasedOnYear()
    });
  }
  createYearPicker(){
    let currentYear = new Date().getFullYear();
    let earliestYear = 2010;

    while (currentYear >= earliestYear) {
     this.yearArray.push({"year":currentYear})
      currentYear -= 1;
    }
  }
 
  searchBasedOnYear(){
    let tempData  = [];
    console.log(this.selectedYear)
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.currentSearchYear = ""+this.selectedYear["year"]

        console.log("dddddddddddddd",this.complaintsTemp);
        this.complaintsTemp.forEach(value =>{

          let yearFromDb:string = ""+value["complaintDate"];
          if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.currentSearchYear  = ''
      this.complaintsTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.complaints = tempData;
  }
}
