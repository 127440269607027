import { Component, OnInit,ViewChild } from '@angular/core';
import {Visita,VisitaView} from '../libs/dataObjects'
import { CBLService } from '../servicios/cbl.service';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { VisitaComponent } from './visita/visita.component';
import { VistasDateService } from '../servicios/vistas-date.service';

@Component({
  selector: 'app-visitas',
  templateUrl: './visitas.component.html',
  styleUrls: ['./visitas.component.scss'],
})
export class VisitasComponent implements OnInit {

  visitasProgramadas: Visita[] = [];
  visitasTemp: Visita[] = [];
  selectedYear:any;

  visitasAttachment:any[] = []

  @ViewChild('visita') visita:VisitaComponent;
  
  constructor(private cbl:CBLService,private httpClient: HttpClient,private selecteYearService:VistasDateService) {}

  ngOnInit(): void
  {
    this.loadVisitas();
    this.getCurrentSelectedYear();
  }

  selectVisita(id,type) 
  {
    this.visita.visitasExistingAttachment =[]
    this.visita.clear()
    this.visita.show(id,type);
  }

  nuevaVisita()
  {
    this.visita.clear()
    this.visita.visitasExistingAttachment =[]
    this.visita.show(0,'view');
  }

  public loadVisitas()
	{
    let url2='horario?'
    +"fields=idHorario,tipo,detalle,inicio,fin"
    +'&where=H.idEmpleado='+this.cbl.id_empleado+" and tipo='visita'"
    +'&order=inicio DESC';

    return this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      this.visitasProgramadas=r['resp'];
      this.visitasTemp=r['resp'];
      this.getAllVisistasAttachment()
    });
  }

  evNuevaVisita(v)
  {
    this.loadVisitas();
    this.selecteYearService.clearSelectedYear();
  }

  getCurrentSelectedYear(){
    this.selecteYearService.getSelectedYear().subscribe(data=>{
        if(data != null){
          this.selectedYear = ""+data['year']
        }else{
          this.selectedYear = null
        }
        this. searchBasedOnYear();
    })
  }

  searchBasedOnYear(){
    let tempData  = [];
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.visitasTemp.forEach(value =>{
          let yearFromDb:string = ""+value["inicio"];
          if(yearFromDb.indexOf(this.selectedYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.visitasTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.visitasProgramadas = tempData;
  }
  getAllVisistasAttachment(){
    return this.httpClient.get(this.cbl.urlBaseBL+"horario/getAllVistasAttachment").toPromise().then((r:any[])=>{
        console.log(r)
        this.visitasAttachment = r
    });
  }

  getfileName(visitas){
    let tempAttachment = this.visitasAttachment.filter(value =>value.idhorario == visitas.idHorario)
    if(tempAttachment.length>0){
      let fileNames = ''
      tempAttachment.forEach(x=>{
        if(fileNames == ''){
          fileNames = x.filename
        }else{
          fileNames=fileNames+","+x.filename
        }
      })
      return fileNames;
    }
  }
}
