<h3 class="title">Identificación</h3>

<div class="box-image" id="recognize-img">
    <img src="assets/img/user.png" alt="Imagen de usuario" class="facial-img">
    <img src="assets/img/check.png" alt="Imagen de usuario" class="verified">
</div>

<div>
    <p class="text-muted">
        Procure mantener centrado el rostro en la cámara.
        Gire ligeramente el rostro de arriba/abajo y de izquierda/
        derecha para entrenar el sistema en diferentes ángulos.</p>
</div>

<p-button label="Cancelar" [routerLink]="['/login']"></p-button>