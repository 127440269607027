

<div> 
    
    <p>
        <!-- <span> Empresa : {{companyName}} {{ClienteName}}</span> -->
        <span> Empresa : {{ClienteName}}</span>
        <span> &nbsp;</span>
        <span> Fecha Alta en la empresa : {{fechaAlta}}</span>
    </p>
   
    
</div>


<div class="alert-box" *ngIf="showalert">"Dispone de {{60-leftoutDays}} días más para acceder al portal. Una vez finalizado el tiempo no se podrá acceder."</div>
<div *ngIf="!readOnly&&show" class="box">
    <p class="reloj">
        <i class="fa fa-calendar icono"></i>{{crono | date: 'dd/MM/YY'}}
        <i class="fas fa-clock icono"></i>{{crono | date: 'HH:mm:ss'}}</p>
    <div class="f-box f-jcsb">
        <div class="f-w1">
            <p-button [disabled]="!inout" label="Fichar entrada" (onClick)="ficharEntrada()">
                
            </p-button>
        </div> 
        <!-- <div class="f-w1">
            <p-button  label="Update Check in" (click)="enterMissedLogInLogOut('updateEntrada')"></p-button>
        </div>-->

        <div class="f-w1">
           Tiempo trabajado hoy: {{totalWoredTime}}
        </div>
        <div class="f-w1">
            <p-button [disabled]="inout" label="Fichar salida" (onClick)="ficharSalida()"></p-button>
        </div>
         <!--  <div class="f-w1">
            <p-button  label="Update Check out" (click)="enterMissedLogInLogOut('updateSalida')"></p-button>
        </div>-->
    </div>
</div>

<p-table [value]="fichajes" *ngIf="show"> 
    <ng-template pTemplate="header">
        <tr>
            <th>Entrada</th>
            <th>Salida</th>
            <th>Saldo</th>
            <th>Tipo</th>
            <th>Modificar</th>
            <!--            <th>Localización</th>-->
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-fichaje let-rowData let-rowIndex="rowIndex">
        <tr>
            <td>{{fichaje.entrada}} <span class="ml-1 higlight" *ngIf="fichaje.entradaUpdate" >[{{fichaje.entradaUpdate.split(" ")[1]}}]</span></td>
            <td>{{fichaje.salida}} <span class="ml-1 higlight" *ngIf="fichaje.salidaUpdate" >[{{fichaje.salidaUpdate.split(" ")[1]}}]</span></td>
            <td>{{fichaje.saldo}}</td>
            <td>{{fichaje.tipo}}</td> 
            <td>
                
                        <!-- <button   (click)="enterMissedLogInLogOut('updateEntrada',fichaje.idHorario)" class="ui-button1 ui-widget ui-state-default ui-corner-all ui-button-text-only" style="font-size:14px!important;margin-left:2px;min-width: 10rem;">Modificar Entrada</button> -->


                        <button   (click)="enterMissedLogInLogOut('updateEntrada',fichaje.idHorario)" class="ui-button1 ui-widget ui-state-default ui-corner-all ui-button-text-only" style="font-size:14px!important;margin-left:2px;">Modificar Entrada</button>

                     <!-- <p-button style="font-size:10px!important" label="Update Check in" (click)="enterMissedLogInLogOut('updateSalida')"></p-button>-->
                   
                      
                        <!-- <button  (click)="enterMissedLogInLogOut('updateSalida',fichaje.idHorario)" class="ui-button1 ui-widget ui-state-default ui-corner-all ui-button-text-only" style="font-size:14px!important;margin-left:2px;min-width: 10rem;">Modificar Salida</button> -->

                        <button  (click)="enterMissedLogInLogOut('updateSalida',fichaje.idHorario)" class="ui-button1 ui-widget ui-state-default ui-corner-all ui-button-text-only" style="font-size:14px!important;margin-left:2px;">Modificar Salida</button>
                       <!-- <p-button class="font-sizee" label="" (click)="enterMissedLogInLogOut('updateEntrada')">Update Check out</p-button>-->
                      
           </td>
            <!--<td>{{fichaje.localizacion}}</td>-->
        </tr>

        <tr *ngIf="clientProjectEmployees.length > 0">  
            <td><strong>Hora/s Minuto/s  Second/s</strong></td> 
            <td><strong>Proyecto</strong></td>
            <td colspan="3"><strong>Informe/Descripcion</strong></td>
           
        </tr>
        <!-- [(ngModel)]="projectemployee.hour" *ngFor="let contact of contacts fichaje.idHorario as c | async; index as i; count as c;"  [rowIndex].i-->
        <tr *ngFor = "let tabledata of clientProjectEmployees count as c;  let i=index; " >
            <td>

                <input type="hidden"  id="idClient_project_employee_horario"  style="width: 60px;" styleClass="fecha-hora" [(ngModel)]="listidClient_project_employee_horario[fichaje.idHorario+'_'+i]"  />

                <input type="hidden"  id="idClient_project_employee"  style="width: 60px;" styleClass="fecha-hora" [(ngModel)]="listidClient_project_employee[fichaje.idHorario+'_'+i]"  />

                <input type="hidden"  id="idClient_project"  style="width: 60px;" styleClass="fecha-hora" [(ngModel)]="listidClient_project[fichaje.idHorario+'_'+i]"  />

                <input type="hidden"  id="idEmpleado"  style="width: 60px;" styleClass="fecha-hora" [(ngModel)]="listidEmpleado[fichaje.idHorario+'_'+i]"  />

                <input type="hidden"  id="idhorario"  style="width: 60px;" styleClass="fecha-hora" [(ngModel)]="listidhorario[fichaje.idHorario+'_'+i]"  />

                <!-- (keypress)="numberOnly($event)"  -->

                <input  pInputText type="text" (keypress)="numberOnly($event)" id="hour"  placeholder="0" (keyup)="compareTime(fichaje.saldo,fichaje.idHorario+'_'+i,fichaje.idHorario,i,c);"  style="width: 60px;" styleClass="fecha-hora" [(ngModel)]="listHour[fichaje.idHorario+'_'+i]"   />  
                
                
                h 
                <input  pInputText type="text" (keypress)="numberOnly($event)" id="minute" placeholder="0"  (keyup)="compareTime(fichaje.saldo,fichaje.idHorario+'_'+i,fichaje.idHorario,i,c);"  style="width: 60px;" styleClass="fecha-hora" [(ngModel)]="listMinute[fichaje.idHorario+'_'+i]"   /> 
                min

                <input  pInputText type="text" (keypress)="numberOnly($event)" id="second" placeholder="0"  (keyup)="compareTime(fichaje.saldo,fichaje.idHorario+'_'+i,fichaje.idHorario,i,c);"  style="width: 60px;" styleClass="fecha-hora" [(ngModel)]="listSecond[fichaje.idHorario+'_'+i]"   /> 
                Sec
            
            </td> 
            <td>
                <!-- {{i}} - {{clientProjectEmployees.length}} - -->
                
                {{tabledata.projectName}}
                
                <!-- - {{rowIndex}} 

                -
                {{fichaje.idHorario}}  -->

                
            </td>
            <td colspan="3">
                <textarea id="work_desc" [rows]="10" [cols]="60" [(ngModel)]="listWorkDesc[fichaje.idHorario+'_'+i]" ></textarea>
            </td>
           
        </tr>

        <tr >
           
            
            <td colspan="4">

                <p-button *ngIf="clientProjectEmployees.length > 0" label="Guardar" (click)="enterProjectEmployee('updateprojectEmployeeHorario',fichaje.idHorario,clientProjectEmployees.length,rowIndex)"></p-button>
                
              
            </td>
            <td></td>
           
        </tr>
        
    </ng-template>






    <ng-template pTemplate="footer">
        <!-- <tr>
            <td colspan="2">Saldo acumulado 111:</td>
            <td>{{total}}</td>
            <td></td>
            <td></td>
        </tr> -->
        <br>
        <br>
        <br>
        <br>
        <br>
        
    </ng-template>

    
    <br>
            <br>
            <br>
            <br>
            <br>
</p-table>
<!--
<div>
    <br>
    <p-button label="Visitas" [routerLink]="'/index/visitas'"></p-button>
</div>
-->
<p-dialog header="Detalles" [positionTop]="100" [contentStyle]="{'max-height':'80vh'}" [modal]="true"
    [blockScroll]="false" [(visible)]="displayAsunto" [style]="{'width':'60%'}">
    <!-- (visible)]="displayAsunto"   {{tarea.tarea}} *ngIf="tarea.consulta!==''"-->
    <!-- <div >
        <br/>
        <p style="font-weight: 300;background-color:ligthgrey;border:1px solid grey">Consulta:</p>
    </div>-->

    <div>
        <form [formGroup]="myForm" (ngSubmit)="onSubmit(myForm)">
            <div>
                <div>
                    <label> Hora: </label>
                    <input class="form-control input-width" formControlName="time" placeholder="time" type="time">
                    
                    <!--<input type="time" class="form-control" placeholder="Time">-->
                </div>


                <div>
                    <label> Fecha: </label>
                    <input class="form-control input-width" formControlName="date" placeholder="date" disabled readonly>
                    <small *ngIf="myForm.get('date').invalid && (myForm.get('date').dirty || myForm.get('date').touched)">Por favor, añada una fecha válida.</small>
                </div>

            </div>
            <small class="text-info" *ngIf="myForm.get('time').invalid && (myForm.get('time').dirty || myForm.get('time').touched)">
                Por favor, indica la hora y los minutos.
            </small>
                    <!-- <input type="text" class="form-control" readonly placeholder="Date">-->
                <!--
            <div class="g-w12field">
            <label for="exampleFormControlTextarea1">Descripción:</label>
            <textarea class="form-control" formControlName="description" id="exampleFormControlTextarea1"
                rows="3"></textarea>
            <small class="text-info"
                *ngIf="myForm.get('description').invalid && (myForm.get('description').dirty || myForm.get('description').touched)">
                Por favor añada un motivo para este cambio.
            </small>
        </div>

    -->

    <div>
        <label for="exampleFormControlTextarea1">Descripción:</label>
        <textarea formControlName="description" id="exampleFormControlTextarea1" [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize"></textarea>
        <small class="text-info"
            *ngIf="myForm.get('description').invalid && (myForm.get('description').dirty || myForm.get('description').touched)">
            Por favor añada un motivo para este cambio.
        </small>
    </div>

    <div class="text-center mt-2">
      <button class="btn btn-primary float-right" type="submit" [disabled]="myForm.invalid">Guardar</button>
      </div>
    </form>

        <!-- <p-timeline align="alternate">
            
        [value]="tarea.tramites" <ng-template pTemplate="content" let-tramite>
                {{tramite.tramite}} 
                <br/><small>[{{tramite.fecha}}]</small>
                <ul *ngIf="tramite.docs.length>0" class="custom-list-tablaCuadro2" (click)="gds.viewDoc($event);">
                    <li class="enlace" *ngFor="let doc of tramite.docs" [attr.data-id]='doc.id'>
                        <i class="fa fa-file"></i>
                        {{doc.documento}}
                    </li>
                    
                </ul>
            </ng-template>
        </p-timeline>-->
    </div>
</p-dialog>

<p-toast position='center'></p-toast>