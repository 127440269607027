<p-dialog header="Detalle visita" [(visible)]="display" [style]="{width: '80vw'}">
    <div class="grid r-grid r-grid12-6" style="width:'100%';">
        <div class="g-w3field">
            <button [disabled]="readOnly" type="button" pButton pRipple label="Ahora -> Inicio" (click)="registrarInicio()"></button>
        </div>
        <div class="g-w3field">
            <p-calendar [locale]="dateConfig" dateFormat="dd/mm/yy" [disabled]="readOnly" [(ngModel)]="visita.inicio"  [showTime]="true" [timeOnly]="false" styleClass="fecha-hora"></p-calendar>
        </div>
        <div class="g-w3field">
            <button [disabled]="readOnly" type="button" pButton pRipple label="Ahora -> Fin" (click)="registrarFin()"></button>
        </div>
        <div class="g-w3field">
            <p-calendar [locale]="dateConfig" dateFormat="dd/mm/yy" [disabled]="readOnly" [(ngModel)]="visita.fin" [timeOnly]="false"  [showTime]="true" styleClass="fecha-hora"></p-calendar>
        </div>
        
        <div class="g-w12field">
            <label>Destino</label>
            <input placeholder="AEAT, notaría, Cliente, dirección..." [disabled]="readOnly" id="destino" type="text" pInputText [(ngModel)]="visita.detalle">
        </div>
        
        <div class="g-w12field">
                <label for="observaciones">Observaciones</label>
                <textarea [disabled]="readOnly" id="observaciones" [rows]="4" [cols]="30" pInputTextarea autoResize="autoResize"
                    [(ngModel)]="visita.obs"></textarea>
        </div>
        <div class="g-w12field">
            <p-fileUpload [showUploadButton]="false" #fileUpload name="demo[]" accept=".pdf,.jpg,.jped,.png,.gif" chooseLabel="Adjunto 1"
             maxFileSize="1000000" (onSelect)="onBasicUpload($event,0)" [disabled]="readOnly" (onClear)="removeFile(0)"></p-fileUpload>
        </div>
        <div class="g-w12field">
            <p-fileUpload [showUploadButton]="false" #fileUpload2 name="demo[]" accept=".pdf,.jpg,.jped,.png,.gif" chooseLabel="Adjunto 2"
             maxFileSize="1000000" (onSelect)="onBasicUpload($event,1)" [disabled]="readOnly" (onClear)="removeFile(1)"></p-fileUpload>
        </div>

        <div class="g-w12field">
            <div class="f-box f-jcsb">
                <div class="f-w1"><p-button [disabled]="readOnly" label="Guardar" (click)="guardar()"></p-button></div>
                <div class="f-w1"><p-button label="Cerrar" (click)="cancelar()"></p-button></div>
            </div>
        </div>
        <div class="g-w12field" *ngIf="visitasExistingAttachment.length>0">
            <h3 class="title">Documentos</h3>
            <p-card class="remark-card">
                <span *ngFor="let attachment of visitasExistingAttachment" >
                    <a (click)="showDocument(attachment)" *ngIf="attachment != null" style="cursor: pointer;">{{attachment.filename}}</a> &nbsp;
                </span>
            </p-card>
        </div>

    </div>        
</p-dialog>

<p-toast position='center'></p-toast>

