<h3 class="title">Cambio de contraseña</h3>

<p-toast position="top-center"></p-toast>

<div class="grid r-grid r-grid12-6" style="width:'100%';">
    <!--<div class="g-w12field">
        <label>Contraseña actual</label>
        <input pInputText id="tipoPermiso"  [(ngModel)]="pwd_actual" placeholder=""/>
    </div>-->
    <div class="g-w12field">
        <label>Nueva contraseña</label>
        <input placeholder="Como mínimo 6 caracteres..." pInputText [(ngModel)]="pwd1" styleClass="fecha-hora"/>
    </div>
    <div class="g-w12field">
        <label>Repetir contraseña</label>
        <input pInputText [(ngModel)]="pwd2" styleClass="fecha-hora"/>
    </div>
    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w1"><p-button label="Cambiar contraseña" (click)="changepwd()" icon="fas fa-paper-plane" iconPos="left"></p-button></div>
        </div>
    </div>
</div>

<!--
<p-tabView>
    <p-tabPanel header="Datos personales">
        <p-scrollPanel [style]="{width: '100%'}">
            <br>
            <div class="example-row" id="personal">
                <div class="image-profile">
                    <div class="box-image">
                        <img src="assets/img/user.png" alt="Imagen de usuario" class="facial-img">
                    </div>
                    <p-button label="Cambiar foto" class="btn-foto"></p-button>
                </div>
                <div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="nombre">Nombre</label>
                            <input id="nombre" type="text" placeholder="Pepe" pInputText>
                        </div>
                    </div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="apellidos">Apellidos</label>
                            <input id="apellidos" type="text" placeholder="Álvarez Jiménez" pInputText>
                        </div>
                    </div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="nif">DNI/NIE</label>
                            <input id="nif" type="text" placeholder="98766563-R" pInputText>
                        </div>
                    </div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="segsocial">Nº Seguridad Social</label>
                            <p-inputNumber id="segsocial" placeholder="98-08076656309"></p-inputNumber>
                        </div>
                    </div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="telefono">Teléfono</label>
                            <p-inputNumber id="telefono" placeholder="65437876"></p-inputNumber>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="direccion">Dirección</label>
                            <input id="direccion" type="text" placeholder="Tipo de Vía, Número, Piso, Puerta"
                                pInputText>
                        </div>
                    </div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="codPostal">Código Postal</label>
                            <p-inputNumber id="codPostal" placeholder="29067"></p-inputNumber>
                        </div>
                    </div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="municipio">Municipio</label>
                            <input id="municipio" type="text" placeholder="Madrid" pInputText>
                        </div>
                    </div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="provincia">Provincia</label>
                            <input id="provincia" type="text" placeholder="Madrid" pInputText>
                        </div>
                    </div>
                </div>
                <div class="example-column">
                    <div class="left-content">
                        <p-button (click)="openDialog()" label="Guardar cambios"></p-button>
                    </div>
                </div>
            </div>
        </p-scrollPanel>
    </p-tabPanel>
    <p-tabPanel header="Datos laborales">
        <p-scrollPanel [style]="{width: '100%'}">
            <div class="example-row" id="laboral">
                <div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="email">Correo empresarial</label>
                            <input id="email" type="email" placeholder="example@mail.com" pInputText>
                        </div>
                    </div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="password">Contraseña</label>
                            <input id="password" type="password" placeholder="************" pPassword
                                [feedback]=false />
                        </div>
                    </div>
                </div>
                <div>
                    <div class="ui-fluid">
                        <div class="p-field">
                            <label for="contrato">Tipo de contrato</label>
                            <input id="contrato" type="text" placeholder="Indefinido" pInputText>
                        </div>
                    </div>
                </div>
                <div class="example-column">
                    <div class="left-content">
                        <p-button (click)="openDialog()" label="Guardar cambios"></p-button>
                    </div>
                </div>
            </div>
        </p-scrollPanel>
    </p-tabPanel>
</p-tabView>

<p-confirmDialog header="Confirmación" [transitionOptions]="'0ms'">
</p-confirmDialog>

-->