import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { CBLService } from '../servicios/cbl.service';
import { VistasDateService } from '../servicios/vistas-date.service';
import {DialogService} from 'primeng/dynamicdialog';
import {ClientprojectdetailComponent } from '../clientprojectdetail/clientprojectdetail.component';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit { 

  historyHorarioArray:any[] = []
  historyHorarioArrayTemp:any[] = []
  currentEmployeeId:any
  selectedYear:any 

  constructor(private cbl:CBLService,private httpClient: HttpClient,private selecteYearService:VistasDateService,public dialogService: DialogService) {}

  ngOnInit(): void {
    this.currentEmployeeId = this.cbl.getEmployeeId();
    this.getAllHorarioByEmployeeId();
  }

  getAllHorarioByEmployeeId(){
      this.httpClient.get(this.cbl.urlBaseBL + 'getHorarioBasedOnId/' + this.currentEmployeeId).subscribe((data:any[])=>{
          if(data){
            this.historyHorarioArray = data
            this.historyHorarioArrayTemp = data
          }
      });
      this.getCurrentSelectedYear()
  }

  getCurrentSelectedYear(){
    this.selecteYearService.getSelectedYear().subscribe(data=>{
        if(data != null){
          this.selectedYear = ""+data['year']
        }else{
          this.selectedYear = null
        }
        this. searchBasedOnYear();
    })
  }

  searchBasedOnYear(){
    let tempData  = [];
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.historyHorarioArrayTemp.forEach(value =>{
          let yearFromDb:string = ""+value["inicio"];
          if(yearFromDb.indexOf(this.selectedYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.historyHorarioArrayTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.historyHorarioArray =[] = tempData;
  }




 

  selectProjectDetail(idEmpleado, inicio, fin, idhorario){

    //alert(idEmpleado+"--"+inicio+"--"+fin+"--"+idhorario);
    const ref = this.dialogService.open(ClientprojectdetailComponent, {
      data:{pagetype:'projectdetail',idEmpleado:idEmpleado,inicio:inicio,fin:fin,idhorario:idhorario},
      header: 'Detalles',
      width: '70%'
  });

  }












}




