import { Component, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { CBLService } from '../servicios/cbl.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  hours: Subscription;
  crono: Date;
  employeeName:any = ''

  fichajes: any[] = [
    { entrada: '08:10:00', salida: '12:10:00', saldo: '4h', observaciones: '' },
    { entrada: '12:15:00', salida: '13:30:00', saldo: '1h 20m' ,  observaciones: 'Visita'},
    { entrada: '13:35:00', salida: '17:30:00', saldo: '1h 25m' ,  observaciones: ''},
  ];

  cols: any[];

  constructor(private userService:CBLService) {
    this.employeeName = this.userService.getEmployeeName();
    this.hours = timer(0, 1000).subscribe((r) => {
      this.crono = new Date();
    });
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'entrada', header: 'Entrada' },
      { field: 'salida', header: 'Salida' },
      { field: 'saldo', header: 'Saldo' },
    ];
  }

  ngOnDestroy() {
    this.hours.unsubscribe();
  }

  ficharEntrada(): void{
    alert(this.crono);
  }

}
