
export class Visita {
  idHorario:number=0;
  idEmpleado:number;
  obs: string;
  //destino: string;
  inicio: Date;
  fin: Date;
  detalle: string;
  estado: number;
  tipo:string="visita";
  
  constructor(v?:Visita)
  {
    if (v!=undefined) Object.assign(this,v);
  }

  //getStrEstado?() {}
}

//type Employee = Array<{ id: number; name: string }>;
export class VisitaView{
  public static estados:string[]=['Pendiente','Realizada'];
  public static estados_dict:Array<{ value:number; label:string }>=[{label:'Pendiente',value:0},{label:'Realizada',value:1}];
  public static getStrEstado(v:Visita) {return VisitaView.estados[v.estado];}
}

export class Solicitud {
  idsolicitud: number=0;
  solicitud: string='';
  fechainicio:Date;
  fechafin?:Date;
  horainicio?:Date=null;
  horafin?:Date=null;
  idtipopermiso?:number=0;
  estado?: string='';
  obs?: string='';
  doc?: string=null;
  contestacion?: string='';
  fechas?: string='';
  idEmpleado?:number=0;

  constructor(v?:Solicitud)
  {
    if (v!=undefined) Object.assign(this,v);
  }
}

export class Documento {
  idUsuario?:Number=0;
  idCliente?:Number=0;
  idEmpleado?:Number=0;
  descripcion:String;
  idMateria?:Number=0;
  idSubMateria?:Number=0;

  constructor(d:Documento)
  {
      Object.assign(this,d);
  }
}

// export class Incidencia {
//   id:number=0;
//   estado?: string;
//   incidencia:string='';
//   fecha:Date=new Date();
//   inicio:string='';
//   fin:string='';
//   observaciones?: string='';
//   docs?:string='';
// }




export class Complaint {
  idComplaint: number=0;
  idEmpleado: number=0;
  complaintDate:Date;
  currentDate:Date;
  complaintPrivacy?: string='';
  complaintType?: string='';
  complaintDesc?: string='';
  complaintAttachDocument?: string='';
  complaintStatus?: string='';
  complaintCommunication?: string='';
  complaintHandledClientId: number=0;
  complaintHandledClientName: string;

  constructor(v?:Complaint)
  {
    if (v!=undefined) Object.assign(this,v);
  }
}


export class complaintcommunication {
  idComplaintCommunication: number=0;
  idComplaint: number=0;
  complaintCommunication?: string='';
  communicationType?: string='';
  communicationByEmployeetId: number=0;
  communicationByClientId: number=0;
  communicationDate:Date;
 
  

  constructor(v?:complaintcommunication)
  {
    if (v!=undefined) Object.assign(this,v);
  }
}



export class Clientproject {
  idClient_project: number=0;
  clientId?: any='';
  project_name?: string='';
  employees?: string=''; 
  startDate?: string=''; 
  endDate?: string=''; 
  total_no?: any='';
  

  constructor(v?:Clientproject)
  {
    if (v!=undefined) Object.assign(this,v);
  }
}
export class Clientprojectemployee {
  idClient_project_employee: number=0;
  clientId?: any='';
  idClient_project?: string='';
  idEmpleado?: string=''; 
  // hour?: string=''; 
  // minute?: string=''; 
  // dateTime?: string=''; 
  // work_desc?: string=''; 
  
  

  constructor(v?:Clientprojectemployee)
  {
    if (v!=undefined) Object.assign(this,v);
  }
}


