import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-entrenamiento',
  templateUrl: './entrenamiento.component.html',
  styleUrls: ['./entrenamiento.component.scss'],
})
export class EntrenamientoComponent implements OnInit {
  progressTraine: number = 0;
  interval: any;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  comenzarEntrenamiento() {
    const classTrainee = document.getElementById('training-img').classList;
    document.getElementById('training-img').classList.add('fail');
    this.interval = setInterval(() => {
      this.progressTraine =
        this.progressTraine + Math.floor(Math.random() * 10) + 1;
      if (this.progressTraine > 30) {
        document.getElementById('training-img').classList.remove('fail');
        document.getElementById('training-img').classList.add('detected');
      }
      if (this.progressTraine >= 100) {
        this.progressTraine = 100;
        document.getElementById('training-img').classList.add('detected');
        document.getElementById('training-img').classList.add('middle');
        clearInterval(this.interval);
        this.router.navigate(['/recognize']);
      }
    }, 1000);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
}
