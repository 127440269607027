<h3 class="title">Entrenamiento</h3>

<div class="box-image" id="training-img">
    <img src="assets/img/user.png" alt="Imagen de usuario" class="facial-img">
    <img src="assets/img/check.png" alt="Imagen de usuario" class="verified">
</div>
<p-progressBar [value]="progressTraine" [style]="{height: '20px',  width: '300px'}"></p-progressBar>

<div>
    <p class="text-muted">Procure mantener centrado el rostro en la cámara.
        Gire ligeramente el rostro de arriba/abajo y de izquierda/
        derecha para entrenar el sistema en diferentes ángulos.</p>
</div>

<div class="panel-access">
    <p-button label="Cancelar" [routerLink]="['/login']"></p-button>
    <p-button label="Iniciar" (click)="comenzarEntrenamiento()"></p-button>
</div>