import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CBLService } from './cbl.service';
@Injectable({
  providedIn: 'root'
})
export class EmpleadoService {
  urlBase: string;
  urlBaseDL: string;
  public subEmployee = new BehaviorSubject([]);
  constructor(private httpClient: HttpClient,private cblservice:CBLService) { 
		//console.log(environment.production);
		//console.log(environment.urlBase);
		this.urlBase=environment.urlBase;
		this.urlBaseDL=this.urlBase+"apidl/"; }
    getEmployeeRecentEntry(id){
      return this.httpClient.get(`${this.urlBaseDL}empleado/recententry/${id}`);
    }
  updateEmployeeMisspunch(data,type,idhorario){
data.idEmpleado=this.cblservice.id_empleado;
data.idCliente=this.cblservice.id_cliente;
		return this.httpClient.post(`${this.urlBaseDL}empleado/misspunch/${type}/${idhorario}`,data);
	}
  updateEmployeeAcceptedToCanceled(idsolicitud){
		return this.httpClient.put(`${this.urlBaseDL}empleado/cancelado/${idsolicitud}`,{estado:"CANCELADO"});
	}
  resetPassword(nif){
		return this.httpClient.post(`${this.urlBaseDL}empleado/resetpassword/${nif}`,{});

	}

  updateEmployeeToCancel(data,idSolicitud,solicitud,date){
   
     data.idEmpleado=this.cblservice.id_empleado;
     data.idCliente=this.cblservice.id_cliente;
     data.idSolicitud=idSolicitud;
     data.solicitud=solicitud;
     data.fecha=date;
     data.estado="SOLICITUD";


     return this.httpClient.post(`${this.urlBaseDL}empleado/cancelSolicitudes/${idSolicitud}/${date}`,data);
  }



}
