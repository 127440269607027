import { Component, OnInit,ViewChild } from '@angular/core';
import {PermisoscadaComponent } from '../permisoscada/permisoscada.component';
import {DialogService} from 'primeng/dynamicdialog';
import {CBLService} from '../servicios/cbl.service'
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { Respuesta } from '../libs/respuesta';
import {formatDate} from '@angular/common';
import {Solicitud,Documento} from '../libs/dataObjects'
import {MessageService} from 'primeng/api';
import {FileUpload} from 'primeng/fileupload';
import { EmpleadoService } from '../servicios/empleado.service';

export interface Permiso {
  id: number;
  permiso: string;
}



/*
export class CalendarioLaboral {
  month: Date;
  vacacionesDays: number[] = [];
  bajasDays: number[] = [];
  permisosDays: number[] = [];
  festivosDays: number[] = [];
}*/

@Component({
  selector: 'app-permisos',
  templateUrl: './permisos.component.html',
  styleUrls: ['./permisos.component.scss'],
})
export class PermisosComponent implements OnInit {
  
  dateConfig:any;
  
  //es: any;

  readOnly:boolean=false;
  dias:Date[];
  inicio:Date=null;
  fin:Date=null; 
  disfrutados:any[]=[];
  disfrutadosTemp:any[]=[];
  disfrutadosFullData:any[]=[];
  currentYear:any

  @ViewChild('envioDoc') envioDoc:any;
  @ViewChild('fileUpload') permiosAttachment:FileUpload;

  yearArray:any[] = [];
  selectedYear:any;
  currentSearchYear:any;
  //dateFrom: Date;
  //dateTo: Date;

  permisos: Permiso[];
  permisosTemp  = []
  selectedPermiso: Permiso; 

  //itemHtml:any;
  itemHtml:any[]=[];

  constructor(public cbl:CBLService,private messageService: MessageService,private httpClient: HttpClient,private empleadoService:EmpleadoService,public dialogService: DialogService) {
    this.dateConfig=this.cbl.dateConfig;
    // this.permisos = [
    //   { permiso: 'Vacaciones Anuales', id: 1 },
    //   { permiso: 'Permisos por días sueltos', id: 2 },
    //   { permiso: 'Días de convenio', id: 3 },
    // ];

    // this.disfrutados=[
    //   {id:1,tipo:'vacaciones',dias:10,permisos:[['1/10/2021 - 15/10/2021',5],['1/10/2021 - 15/10/2021',4]]},
    //   {id:2,tipo:'enfermedad',dias:3,permisos:[['1/6/2021',1],['1/10/2021 - 2/10/2021',2]]}
    // ];
  }

  ngOnInit(): void 
  {
    let url2='tipopermiso?'
    +"fields=idTipoPermiso as id,permiso"
    +'&order=permiso';

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      console.log(r);
      this.permisos=r.resp;
    });

    this.loadPermisos();
    this.createYearPicker() 
  }

  loadPermisos()
  {
    let url2='solicitudes?'
    +'&fields=idtipopermiso,solicitud,fechainicio,fechafin,horainicio,horafin,fechas,doc,estado,idsolicitud,filename'
    +'&where=idEmpleado='+this.cbl.id_empleado+" and idTipoPermiso!=0"
    +'&order=fechainicio DESC'
    +'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,doc,estado,idsolicitud,filename';
    
    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      for(let p of r.resp)
      {
          let dias=0;
          for(let d of p.permisos)
          {
            let cd=d.fechas.split(",").length;
            d['dias']=cd; 
            // if (cd>1)
            //   d['periodo']=formatDate(d.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(d.fechafin,'dd/MM/yyyy','en-US');
            // else
            //   d['periodo']=formatDate(d.fechainicio,'dd/MM/yyyy','en-US');

            // if(d.horainicio!=null && d.horafin!=null)
            // {
            //   d['periodo']+=" "+d.horainicio.substr(0,5)+"-"+d.horafin.substr(0,5);
            // }

            if (cd>1)
            {
              //d['periodo']=formatDate(d.fechainicio,'dd/MM/yyyy','en-US');
              const dateYear = new Date(d.fechainicio);
              let mm=d.fechas.split(",");
              ///let itemHtml="/n";
              
              this.itemHtml=[];
              for(var s=0;s<mm.length;s++)
              {

              let ddd=mm[s].split("/");
              let dateItem=dateYear.getFullYear()+"-"+ddd[1]+"-"+ddd[0];
              let date1 = new Date(dateItem).getTime();
              let date2 = new Date().getTime();

              if (date1 < date2) {
                let dateItem=(dateYear.getFullYear()+1)+"-"+ddd[1]+"-"+ddd[0];
              } 
              

              this.itemHtml[s]=dateItem;

              }
              
              d['periodo']=this.itemHtml;
            }
            else{
              const dateYear = new Date(d.fechainicio);
              //document.write("The current month is " + monthNames[dateYear.getFullYear()]);
              //let dateItem=dateYear.getFullYear()+d.fechas+"/"+dateYear.getFullYear();
              let ddd=d.fechas.split("/");
              let dateItem=dateYear.getFullYear()+"-"+ddd[1]+"-"+ddd[0];
              let date1 = new Date(dateItem).getTime();
              let date2 = new Date().getTime();

              if (date1 < date2) {
                let dateItem=(dateYear.getFullYear()+1)+"-"+ddd[1]+"-"+ddd[0];
              } 
              this.itemHtml=[];
              this.itemHtml[0]=dateItem;

              d['periodo']=this.itemHtml;
            }
            // if(d.horainicio!=null && d.horafin!=null)
            // {
            //   d['periodo']+=" "+d.horainicio.substr(0,5)+"-"+d.horafin.substr(0,5);
            // }




            if(d.estado!="RECHAZADA" && d.estado!="SOLICITUD" && d.estado!="CANCELADO") dias+=cd;
            //if(d.estado!="SOLICITUD" && d.estado!="CANCELADO") dias+=cd;
            console.log("dias.....",dias)
            p['dias']=dias;

            if(new Date()>new Date(d.fechafin))
            {
              d['disabled']=true;  
            }
            else{
              d['disabled']=false; 
            }

            if(d.estado!="RECHAZADA" && d.estado!="CANCELADO")
            {
              d['showcada']=true;  
            }
            else{
              d['showcada']=false; 
            }




          }
      } 
      console.log(r.resp)
      console.log(r.resp.permisos)
      this.disfrutados=r.resp;
      this.disfrutadosTemp=r.resp;
      this.disfrutadosFullData = r.resp;
      this.selectedYear = {year:new Date().getFullYear()};
      this.searchBasedOnYear()
    });
  }

  async solicitar()
  {
    if(this.selectedPermiso==undefined || this.selectedPermiso==null)
    {
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'El campo Tipo Permiso es obligatorio!'});
      return;
    }

    if(this.dias==undefined || this.dias==null)
    {
      this.messageService.add({severity: 'error', summary: 'Error', detail: 'El campo Fecha es obligatorio!'});
      return;
    }

    if(this.inicio!=undefined || this.fin!=undefined)
    {
      if(this.inicio==undefined || this.fin==undefined)
      {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Debe rellenar hora de inicio y de fin!'});
        return;
      }
      if(this.inicio>this.fin)
      {
        this.messageService.add({severity: 'error', summary: 'Error', detail: 'Hora de fin debe ser mayor que hora de inicio!'});
        return;
      }
    }

    let sol:Solicitud=new Solicitud();
    
    if(this.envioDoc.files.length>0)
    {
      
      this.readFile(this.envioDoc.files[0],sol,'new creation')
    }else{
      this.saveLeave(sol)
    }
  }

  myUploader(event)
	{
		console.log(event);
	}

  createYearPicker(){
    this.currentYear = new Date().getFullYear();
    let earliestYear = 2010;
    let tempYear = new Date().getFullYear();
    while (tempYear >= earliestYear) {
     this.yearArray.push({"year":tempYear})
     tempYear -= 1;
    }
    
  }

  searchBasedOnYear(){
    let tempData  = [];
    console.log("this,disfrutadosfulldata..",this.disfrutadosFullData)
    this.disfrutadosTemp = JSON.parse(JSON.stringify(this.disfrutadosFullData))
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.currentSearchYear = ""+this.selectedYear["year"]
        this.disfrutadosTemp.forEach(value =>{
          let dias=0;
          console.log("value...",value)
          let permisos = [] = value["permisos"]
          let tempPermisos = [];

          let day=0;
          permisos.forEach(leaves=>{
            let yearFromDb:string = ""+leaves["fechainicio"];
            if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
            
              let cd=leaves.fechas.split(",").length;
              leaves['dias']=cd; 
              dias+=cd;
          //  value['dias']=dias;

              // day=day+cd;
              if(leaves.estado=='ACEPTADA')
              {
                day=day+cd;
              }
              tempPermisos.push(leaves);
            }       
          }) 

          value["emp_days"] = day;
          value["permisos"] = tempPermisos;
          tempData.push(value)
        });         
    }else{
      this.currentSearchYear  = ''
      this.disfrutadosTemp.forEach(value =>{
        tempData.push(value);
      });
    }
   // if(d.estado!="RECHAZADA") dias+=cd;
   // p['dias']=dias;
    this.disfrutados =[] =  tempData;
  }

  onBasicUpload(event,fic){
   
    let sol:Solicitud=new Solicitud(fic);
    if(event.files.length>0)
    {
      for (const file of event.currentFiles) {
        this.readFile(file,sol);
      }
    }
  }

 readFile(file: File,sol,uploadFrom ='') {
    let data:any = {
      idsolicitudttachment:0,
      filename:file['name'],
      filetype:file['type']
    }
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let readerData:any  = reader.result
      let readerDataArray:any[] = readerData.split(',');
      data['filedata'] = JSON.stringify(readerDataArray[0]);
      data['other'] = JSON.stringify(readerDataArray[1]);
      this.cbl.insertSolicitudAttachment(data).then(res=>{
        sol['doc'] = res['insertId']
        sol['filename'] = data['filename']
        if(uploadFrom == ''){
          this.cbl.updateSolicitud(sol).then(res=>{
            this.loadPermisos()
          })
        }else{
          this.saveLeave(sol)
        }
      })
    };
    
  }

  saveLeave(sol){
    let minimo:Date=this.dias[0];
    minimo=this.dias.reduce((minimo,f,i)=>{if(f<minimo) return f; else return minimo;})
    let maximo:Date=this.dias[0];
    maximo=this.dias.reduce((maximo,f,i)=>{if(f>maximo) return f; else return maximo;})
    
    sol.fechainicio=minimo;
    sol.fechafin=maximo;
    //sol.fechainicio=formatDate(minimo,'yyyy-MM-dd','en-US');
    //sol.fechafin=formatDate(maximo,'yyyy-MM-dd','en-US');
    console.log(this.inicio,this.fin);
    if(this.inicio!=null) sol.horainicio=this.inicio; //new Date(formatDate(this.inicio,'H:mm','en-US'));
    if(this.fin!=null) sol.horafin=this.fin; //new Date(formatDate(this.fin,'H:mm','en-US'));
    sol.idtipopermiso=this.selectedPermiso.id;
    sol.solicitud=this.selectedPermiso.permiso;
    sol.estado="SOLICITUD";
    sol.idEmpleado=this.cbl.id_empleado;
    sol.fechas=this.dias.map((f)=>{return formatDate(f,'dd/MM','en-US')}).join(",");
    this.cbl.insertSolicitud(sol).then((r)=>{
      console.log(r);
      this.messageService.add({severity: 'info', summary: 'Solicituda realizada', detail: 'Solicitud de permiso realizada con éxito!'});
      this.loadPermisos();
      this.inicio=null;
      this.fin=null;
      this.dias=null;
      this.envioDoc.clear(); 
      this.selectedPermiso=null;

      this.cbl.emailToCliente(this.cbl.id_cliente,"Ha recibido una nueva solicitud en el Portal del Cliente",`Estimado ${this.cbl.name_cliente}:\n\nUn empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de permiso << ${sol.solicitud} >> para los días: (${sol.fechas}).\n\nSaludos`); 

      // this.cbl.emailToCollaboratorForLeave(this.cbl.id_cliente,"Ha recibido una nueva solicitud en el Portal del cliente de la empresa COSOFT, S.L.",`Estimado ${this.cbl.name_cliente}:\n\nEl empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de permiso << ${sol.solicitud} >> para los días: (${sol.fechas}).\n\nDebe de aceptar o rechazar el permiso.\n\nSaludos.`);

      this.cbl.emailToCollaboratorForLeave(this.cbl.id_cliente,"Ha recibido una nueva solicitud en el Portal del cliente de la empresa ${this.cbl.name_cliente}",`Estimado ${this.cbl.name_cliente}:\n\nEl empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de permiso << ${sol.solicitud} >> para los días: (${sol.fechas}).\n\nDebe de aceptar o rechazar el permiso.\n\nSaludos.`);

 


      // this.cbl.emailToDepartmentFromEmployee(this.cbl.id_empleado,"Ha recibido una nueva solicitud en el Portal del cliente de la empresa COSOFT, S.L.",`Estimado responsable (${this.cbl.name_cliente}):\n\nEl empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de permiso << ${sol.solicitud} >> para los días: (${sol.fechas}).\n\nDebe aceptar o rechazar el permiso.\n\nPuede acceder al portal: https://portalclientes.oficinadigital.eu\n\nSaludos`);

      this.cbl.emailToDepartmentFromEmployee(this.cbl.id_empleado,`Ha recibido una nueva solicitud en el Portal del cliente de la empresa ${this.cbl.name_cliente}`,`Estimado responsable (##DEPARTMENT_NAME##):\n\nEl empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de permiso << ${sol.solicitud} >> para los días: (${sol.fechas}).\n\nDebe aceptar o rechazar el permiso.\n\nPuede acceder al portal: https://portalclientes.oficinadigital.eu\n\nSaludos`);


///////////////////////////////////////Now Closed/////////////////////////////////////

    


      







    });
  }

  showDocument(fic){
    this.cbl.getSolicitudAttachment(fic.doc).then(res=>{
      let data  = res[0];
      console.log("this is the response once no file: " + data);
      this.cbl.viewSolicitudAttachment(data.filepath+"/"+data.filename).then(r=>{    
       let filedata:string = data.filedata
       console.log(typeof filedata)
       filedata = filedata.substring(1, filedata.length - 1)   
       console.log(filedata)
        const linkSource =  filedata+","+r[0]['data'];
        console.log(linkSource)
        const downloadLink = document.createElement("a");
        const fileName = data.filename;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      })
    })
    
  }
  updateCancelado(fic){
this.empleadoService.updateEmployeeAcceptedToCanceled(fic).subscribe(()=>{
  this.loadPermisos();
})
  }





  selectCancelarCada(id){
   // alert(id);
    const ref = this.dialogService.open(PermisoscadaComponent, {
      data:{id:id},
      header: 'Cancelar Solicitudes',
      width: '70%'
  });

  ref.onClose.subscribe((ret) => {

    this.loadPermisos();  
    
   
  });


  }
 





}
