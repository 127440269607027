import { Component, OnInit } from '@angular/core';
import { CBLService } from '../servicios/cbl.service';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { MonthStrPipe } from '../libs/MonthStr.pipe';

export interface Nomina {
  fecha: Date;
  id: string;
  importe: number;
  documento: string;
}

@Component({
  selector: 'app-nomina',
  templateUrl: './comunicacion.component.html',
  styleUrls: ['./comunicacion.component.scss'],
})
export class ComunicacionComponent implements OnInit {
  nominas: Nomina[] = [];
  nominasTemp: Nomina[] = [];
  yearArray:any[] = [];
  selectedYear:any;
  message = "Documentos del año";
  currentSearchYear:any;
  documento: any;
  
  /*[
    {
      id: '1',
      fecha: new Date(),
      importe: 1500,
      documento: 'Junio 2020',
    },
    {
      id: '2',
      fecha: new Date(),
      importe: 1875,
      documento: 'Mayo 2020',
    },
    {
      id: '3',
      fecha: new Date(),
      importe: 1255,
      documento: 'Abril 2020',
    },
    {
      id: '4',
      fecha: new Date(),
      importe: 1575,
      documento: 'Marzo 2020',
    },
    {
      id: '5',
      fecha: new Date(),
      importe: 1575,
      documento: 'Junio 2020',
    },
    {
      id: '6',
      fecha: new Date(),
      importe: 1575,
      import { Permiso } from './../permisos/permisos.component';
documento: 'Julio 2020',
    },
  ];*/

  constructor(private cbl:CBLService,private httpClient: HttpClient) {}

  ngOnInit(): void {
    let sutractedDate =  this.subtractDays(15);
    this.httpClient.get(this.cbl.urlBaseBL+"communication/getbasedonEmpId/"+this.cbl.id_empleado).toPromise().then((r:any)=>{
      console.log("rrrr...",r)
    //  document:communications,permiso:resp
     /* r.permiso.sort(function(a, b) {
        var keyA = new Date(a.fecha),
          keyB = new Date(b.fecha);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });*/
      /*r.document.sort(function(a, b) {
        var keyA = new Date(a.fecha),
          keyB = new Date(b.fecha);
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });*/
      console.log("rrrr.....",r)
     // this.nominas=[]= r.permiso.filter(value  => new Date(value['fecha']).getTime()>=sutractedDate.getTime());
      this.nominas=r.permiso;
      this.documento=r.document;
      
       // this.nominas=nominas
        this.nominasTemp = r.permiso;
      }); 
      this.createYearPicker();
  }
  //SELECT * FROM `documentos` WHERE `idEmpleado` = 10264 ORDER BY `documentos`.`fechaRecepcion` ASC 
  subtractDays(numOfDays, date = new Date()) {
    date.setDate(date.getDate() - numOfDays);  
    return date;
  }

  public click(ev)
  {
    //console.log(ev);
    this.cbl.viewIDDoc(ev);
  }

  createYearPicker(){
    let currentYear = new Date().getFullYear();
    let earliestYear = 2010;

    while (currentYear >= earliestYear) {
     this.yearArray.push({"year":currentYear})
      currentYear -= 1;
    }
  }

  searchBasedOnYear(){
    let tempData  = [];
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.currentSearchYear = ""+this.selectedYear["year"]
        this.nominasTemp.forEach(value =>{
          let yearFromDb:string = ""+value["fecha"];
          if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.currentSearchYear  = ''
      this.nominasTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.nominas = tempData;
  }
}
