import { Component, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-facial',
  templateUrl: './facial.component.html',
  styleUrls: ['./facial.component.scss'],
})
export class FacialComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    setTimeout(() => {
      document.getElementById('recognize-img').classList.add('fail');
    }, 2000);

    setTimeout(() => {
      document.getElementById('recognize-img').classList.remove('fail');
      document.getElementById('recognize-img').classList.add('detected');
    }, 5000);

    setTimeout(() => {
      document.getElementById('recognize-img').classList.add('middle');
    }, 7000);

    setTimeout(() => {
      this.router.navigate(['/index']);
    }, 9000);
  }
}
