
export class Utilidades{
  static compareObjs(value: object,value2: object): boolean {
    if(typeof(value)!='object' && typeof(value2)!='object') return (value==value2);
	for (let key in value)
	{
		let v1 = value[key];
		let v2 = value2[key];
		let comp=Utilidades.compareObjs(v1,v2);
		if (!comp) return false;
	}	
	return true;
  }
  
  static computeDiffs(obj1:object,obj2:object):object{
	var objR={};  
	for (let key in obj1)
	{
		let valueLocal = obj1[key];
		let value = obj2[key];
		let comp=Utilidades.compareObjs(valueLocal,value);
		if (!comp)
		{
			console.log(key+": "+value+" -> "+valueLocal+" "+comp);
			objR[key]=valueLocal;
		}
	}
	return objR;
  }
  
  static range(min, max, step=1) {
		step = step || 1;
		var input = [];
		for (var i = min; i <= max; i += step) {
			input.push(i);
		}
		return input;
	};

	static packVLO(s:object)
	{
		//return this.lzw_encode(btoa(JSON.stringify(s)));

		//console.log(JSON.stringify(s));
		var cad=JSON.stringify(s);
		//var l=cad.length;
		var b64=btoa(cad); 
		cad= String.fromCharCode(Math.random()*16+97)+String.fromCharCode(Math.random()*16+97)+b64+String.fromCharCode(Math.random()*16+97)+String.fromCharCode(Math.random()*16+97);
		//console.log(cad);
		return cad;

		//var base64EncodedStr = btoa(encodeURIComponent(this.lzw_encode(JSON.stringify(s))));
		//console.log(base64EncodedStr);

		//var cad=btoa(JSON.stringify(s));
		//console.log(this.lzw_encode(cad));
		//console.log(this.lzw_decode(this.lzw_encode(cad)));
		//console.log(btoa(this.lzw_encode(cad)));
		//console.log(atob(this.lzw_decode(this.lzw_encode(cad))));
		//return "Zv"+btoa(JSON.stringify(s))+"Xc";
	};

	// LZW-compress a string
	static lzw_encode(s) {
		var dict = {};
		var data = (s + "").split("");
		var out = [];
		var currChar;
		var phrase = data[0];
		var code = 256;
		for (var i=1; i<data.length; i++) {
			currChar=data[i];
			if (dict[phrase + currChar] != null) {
				phrase += currChar;
			}
			else {
				out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
				dict[phrase + currChar] = code;
				code++;
				phrase=currChar;
			}
		}
		out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
		for (var i=0; i<out.length; i++) {
			out[i] = String.fromCharCode(out[i]);
		}
		return out.join("");
	}

	// Decompress an LZW-encoded string
	static lzw_decode(s) {
		var dict = {};
		var data = (s + "").split("");
		var currChar = data[0];
		var oldPhrase = currChar;
		var out = [currChar];
		var code = 256;
		var phrase;
		for (var i=1; i<data.length; i++) {
			var currCode = data[i].charCodeAt(0);
			if (currCode < 256) {
				phrase = data[i];
			}
			else {
			phrase = dict[currCode] ? dict[currCode] : (oldPhrase + currChar);
			}
			out.push(phrase);
			currChar = phrase.charAt(0);
			dict[code] = oldPhrase + currChar;
			code++;
			oldPhrase = phrase;
		}
		return out.join("");
	}
}