import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { IndexComponent } from './index/index.component';
import { FichajeComponent } from './fichaje/fichaje.component';
import { FacialComponent } from './facial/facial.component';
import { EntrenamientoComponent } from './entrenamiento/entrenamiento.component';
import { PermisosComponent } from './permisos/permisos.component';
import { PermisoscadaComponent } from './permisoscada/permisoscada.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { IncidenciasComponent } from './incidencias/incidencias.component';
import { JornadaComponent } from './jornada/jornada.component';
import { JornadasMainComponent } from './jornadas-main/jornadas-main.component';
import { NominaComponent } from './nomina/nomina.component';
import { ComunicacionComponent } from './comunicacion/comunicacion.component';
import { PerfilComponent } from './perfil/perfil.component';
import { NotificacionComponent } from './notificacion/notificacion.component';
import { VisitasComponent } from './visitas/visitas.component';
import { HistoryComponent } from './history/history.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ComplaintsComponent } from './complaints/complaints.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  //{path: '', pathMatch: 'full', component: LoginComponent,},
  { path: 'code/:id', component: LoginComponent },
  {path: 'login/code/:id/apikey/:key', component: LoginComponent},
  {path: 'login/user/:id/apikey/:key/:empId', component: LoginComponent},
  {
    path: 'login',
    component: LoginComponent,
  },
  { path: 'recognize', component: FacialComponent },
  { path: 'training', component: EntrenamientoComponent },
  {
    path: 'index',
    component: IndexComponent,
    children: [
      { path: '', component: HomeComponent },
      { path: 'home', component: HomeComponent },
      { path: 'permisos', component: PermisosComponent },
      { path: 'docs', component: DocumentosComponent },
      { path: 'incidencias', component: IncidenciasComponent },
      { path: 'jornadas', component: JornadasMainComponent,
        children:[{path:'fichar',component: FichajeComponent},{path:'saldos',component: JornadaComponent},{path:'visitas',component: VisitasComponent},{path:'history',component: HistoryComponent}]
      },
      { path: 'nominas', component: NominaComponent },
      { path: 'comunicacion', component: ComunicacionComponent },
      { path: 'perfil', component: PerfilComponent },
      { path: 'notificaciones', component: NotificacionComponent },
      { path: 'visitas', component: VisitasComponent },
      { path: 'changepassword', component: ChangepasswordComponent },
      { path: 'complaints', component: ComplaintsComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes
                                ,{ useHash: true, relativeLinkResolution: 'legacy' }
                                )],

  exports: [RouterModule],
})
export class AppRoutingModule {}
