import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {CBLService} from '../servicios/cbl.service';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ChangepasswordComponent implements OnInit {


  newPassword:string="";
  confirmPassword:string="";

  constructor(private messageService: MessageService,public cbl:CBLService) {
    
   }

  ngOnInit(): void {

  }
  changePassword()
  { 
   
    if(this.newPassword=='')
    {
      //alert("New Password Should not be blank");
      this.messageService.add({severity: 'error',summary: 'Error!',detail: 'New Password Should not be blank!'});
      return;
    }
    if(this.confirmPassword=='')
    {
      //alert("Confirm Password Should not be blank");
      this.messageService.add({severity: 'error',summary: 'Error!',detail: 'Confirm Password Should not be blank!'});
      return;
    }
    if (this.newPassword!=this.confirmPassword)
    {
      this.messageService.add({severity: 'error',summary: 'Error!',detail: 'New Password and Confirm Password not same!'});
      return;
    }

    console.log(this.cbl.id_empleado);
    console.log(this.newPassword);

    this.cbl.updatepassword(this.cbl.id_empleado,this.newPassword).then((r)=>{
      console.log(r);
    });
    this.messageService.add({severity: 'info',summary: 'Success!',detail: 'Password successfully Changed'});

    //alert("aaaa");
  }

}
