
<app-visita #visita id="visita" (nueva_visita)="evNuevaVisita($event)"></app-visita>-

<p-table [value]="visitasProgramadas" [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5"
            [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} de {totalPages}">
            <ng-template pTemplate="header">
                <tr>
                    <th>Inicio</th>
                    <th>Fin</th>
                    <th>Documento/s</th>
                    <th>Destino</th>
                    <th width="8%"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-visitaProg>
                <tr>
                    <td>{{visitaProg.inicio | date: 'dd/MM/yyyy HH:mm'}}</td>
                    <td>{{visitaProg.fin | date: 'dd/MM/yyyy HH:mm'}}</td>
                    <td>{{getfileName(visitaProg)}}</td>
                    <td>
                        {{visitaProg.detalle}}
                        <!--<span [class]="'customer-badge status-' + getStrEstado(visitaProg)">{{getStrEstado(visitaProg)}}</span>-->
                    </td>
                    <td>
                            <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-eye" class="p-button-outlined"
                            (click)="selectVisita(visitaProg.idHorario,'view')"></button>
                            <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-edit" class="p-button-outlined"
                            (click)="selectVisita(visitaProg.idHorario,'edit')"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="f-box f-jcsb" style="margin-top:10px">
            <div class="f-w1"><p-button (click)="nuevaVisita();" label="Nueva visita"></p-button></div>
        </div>

