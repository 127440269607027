<div *ngIf="pagetype=='detail'" >
    <div    class="p-grid p-justify-start" *ngFor="let itememp of allclientprojectemployee">
        <div class="p-col-12 p-md-12 p-lg-12">
        
        <fieldset>
            <div class="p-grid">
                <div class="p-col-12">
        <legend><strong> Trabajador: </strong> {{itememp.employeeName}} </legend> 
        <table class="clientprojectdetail-table" >
            <thead>
              <th>Fecha</th>
              <th>Hora/s Minuto/s Second/s</th>
              <th>Proyecto</th>
              <th>Descripcion</th>
            </thead>
            <tbody>
              <tr *ngFor="let itemempsub of itememp.mainDetail ">
                <td> {{itemempsub.dateTime}}</td>
                <td> {{itemempsub.hour}}h {{itemempsub.minute}}min {{itemempsub.second}}sec</td>
                <td> {{itemempsub.projectName}}</td>
                <td> {{itemempsub.work_desc}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
        </fieldset>

    </div>
</div>
</div>






















<div *ngIf="pagetype=='projectdetail'" >

  <div class="grid r-grid12-6">

    <div class="g-w3field">
      <strong>Proyecto</strong> : {{allclientprojectemployee[0].project_name}}
    </div> 

    <div class="g-w3field">
      <strong>Fecha inicio</strong> : {{allclientprojectemployee[0].startDate}}
    </div> 

    <div class="g-w3field">
      <strong>Fecha fin</strong> : {{allclientprojectemployee[0].endDate}}
    </div> 
             
   

  </div>



  <div class="grid r-grid12-6">

     
             
    <div class="g-w3field"> 
      <strong>Total horas</strong> : {{allclientprojectemployee[0].total_hour}}
    </div>        
    <div class="g-w3field">
      <strong>Tiempo trabajado</strong> : {{allclientprojectemployee[0].worked_hour}}
    </div>
    <div class="g-w3field">
      <strong>Tiempo restante</strong> : {{allclientprojectemployee[0].remaining_hour}}
    </div> 

    
  </div>





<div   class="p-grid p-justify-start" *ngFor="let itememp of allclientprojectemployee">


  
  
  <div class="p-col-12 p-md-12 p-lg-12">



  
  <fieldset>
      <div class="p-grid">
          <div class="p-col-12">
  <legend><strong> Trabajador: </strong> {{itememp.employeeName}} </legend>
  <table class="clientprojectdetail-table" >
      <thead>
        <th>Fecha</th>
        <th>Horas trabajadas</th>
        <!-- <th>Hora/s Minuto/s Second/s</th> -->
        <!-- <th>Proyecto</th> -->
        <th>Descripcion</th>
      </thead>
      <tbody>
        <tr *ngFor="let itemempsub of itememp.mainDetail ">
          <td> {{itemempsub.dateTime}}</td>
          <td> {{itemempsub.hour}}h {{itemempsub.minute}}min {{itemempsub.second}}sec</td>
          <!-- <td> {{itemempsub.hour}}h {{itemempsub.minute}}min {{itemempsub.second}}sec</td>
          <td> {{itemempsub.projectName}}</td> -->
          <td> {{itemempsub.work_desc}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
  </fieldset>

</div>
</div>
</div>
















<p-toast position='center'></p-toast>