<!-- <h3 class="title">Permisos y licencias</h3> -->



<div class="grid r-grid r-grid12-6" style="width:'100%';">

    <div class="g-w12field">
    <div class="header-permisos">
   
        <div class="center " >
            <h3 class="title">Permisos y licencias</h3>
    
        </div>
     
    </div>
    </div>


    <div class="g-w12field">
        <label>Tipo de permiso</label>
        <p-dropdown id="tipoPermiso" [options]="permisos" [(ngModel)]="selectedPermiso"
        placeholder="Seleccione" optionLabel="permiso" [showClear]="true"></p-dropdown>
    </div>
    <div class="g-w6field">
        <label>Fecha/s</label>
        <p-calendar selectionMode="multiple" [locale]="dateConfig" dateFormat="dd/mm/yy" [disabled]="readOnly" [(ngModel)]="dias"  [timeOnly]="false" styleClass="fecha-hora"></p-calendar>
    </div>
    <div class="g-w3field">
        <label>Hora inicio</label>
        <p-calendar [timeOnly]="true" [locale]="dateConfig" dateFormat="dd/mm/yy" [disabled]="readOnly" [(ngModel)]="inicio"  [showTime]="true"  styleClass="fecha-hora"></p-calendar>
    </div>
    <div class="g-w3field">
        <label>Hora fin</label>
        <p-calendar [timeOnly]="true" [locale]="dateConfig" dateFormat="dd/mm/yy" [disabled]="readOnly" [(ngModel)]="fin"  [showTime]="true" styleClass="fecha-hora"></p-calendar>
    </div>

    <div class="g-w12field">
        <label>Adjunte fichero si y solo si dispone del certificado o documento justificativo ahora mismo. También lo puede añadir más adelante.</label>
        <p-fileUpload fileLimit="1" auto="true" multiple="false"  id="envioDoc" #envioDoc [showUploadButton]="false" chooseLabel="Adjuntar fichero"   maxFileSize="50000000" ></p-fileUpload>
        </div> 

    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w1"><p-button label="Solicitar" (click)="solicitar()" icon="fas fa-paper-plane" iconPos="left"></p-button></div>
        </div>
    </div>

    <div class="g-w12field">
        <p-table [value]="disfrutados" dataKey="label">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:60px"></th>
                    <th align="left">
                        <span  *ngIf="currentSearchYear == currentYear">Permisos y licencias (año en curso):</span>
                        <span  *ngIf="currentSearchYear != currentYear">Permisos y licencias ({{currentSearchYear}}):</span>
                        <div style=" display:flex;  flex-direction: row; float: right;">
                            <p-dropdown
                                [options]="yearArray"
                                placeholder="Select a Year" 
                                optionLabel="year"
                                [showClear]="true"
                                [(ngModel)]="selectedYear"
                                [style]="{'font-size':'10px'}"
                            >
                            </p-dropdown>
                            <button class="button" (click)="searchBasedOnYear()" style="color: white;
                            background-color: #0e1e45;
                            border: 1.5px solid #6f61fd;
                            margin-left: 4px; 
                            cursor: pointer;">Buscar</button>
                        </div>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-emp let-expanded="expanded" >
              
                <tr *ngIf="emp.permisos.length>0">
                    <td style="width:60px"><button type="button" pButton pRipple [pRowToggler]="emp" class="ui-button-text ui-button-rounded ui-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button></td>
                    <!-- <td style="text-align:left;padding-left:1rem">{{emp.label}} ({{emp.dias}} días)</td> -->

                    <td style="text-align:left;padding-left:1rem">{{emp.label}} ({{emp.emp_days}} días)</td>
                </tr>
            </ng-template>
        
            <ng-template pTemplate="rowexpansion" let-emp >
                <tr *ngIf="emp.permisos.length>0">
                    <td colspan="2"><div class="p-p-3">
                <p-table [value]="emp.permisos" >
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Permiso</th>
                            <th>Días</th>
                            <th>Documento</th>
                            <th>Estado</th>
                            <th>Acciones</th>
                          
                        </tr>
                    </ng-template>
                   <ng-template pTemplate="body" let-fic>

                        <tr>
                            <!-- <td>{{fic.periodo}}</td> -->
                            <td>
                                <ul>
                                    <li *ngFor="let hero of fic.periodo">{{hero}}</li>
                                </ul>
                            </td>
                            <td>{{fic.dias}}</td>
                            <td><a (click)="showDocument(fic)">{{fic.filename}} {{fic.doc}}</a></td>
                            <td>{{fic.estado}}</td>
                            
                            <td>
                                <p-button *ngIf="fic.estado=='ACEPTADA'"
								class="ui-button-raised ui-button ui-widget ui-state-default"
								(click)="updateCancelado(fic.idsolicitud)" label="Cancelar todo"></p-button> 

                                <p-fileUpload fileLimit="1" auto="true" multiple="false" mode="basic" #fileUpload  chooseLabel="Subir"
                                maxFileSize="1000000" (onSelect)="onBasicUpload($event,fic)" [disabled]="(fic.doc != null && fic.filename !=='')" ></p-fileUpload>

                                <!-- <button *ngIf="fic.estado=='ACEPTADA'" title='Cancelar Cada' pButton pRipple type="button" icon="fas fa-eye" class="p-button-outlined"
                                (click)="selectCancelarCada(fic.idsolicitud)" [disabled]="fic.disabled" label="Cancelar x dias"></button> -->

                                <button  title='Cancelar Cada' pButton pRipple type="button" class="p-button-outlined"
                                (click)="selectCancelarCada(fic.idsolicitud)" [disabled]="fic.disabled" label="Cancelar x dias" *ngIf="fic.showcada"  ></button>

                            </td>

                          
                        </tr>

                  </ng-template>
                </p-table></div></td></tr>
            </ng-template>
        </p-table>
    </div>
    <br>
            <br>
            <br>
            <br>
            <br>

</div>


<p-toast position='center'></p-toast>

<!--
<p-tabView>
    <p-tabPanel header="Vacaciones">
        <p-scrollPanel [style]="{width: '100%'}">
            <br>
            <div class="example-row">
                <div class="ui-fluid">
                    <p>Disponibilidad:</p>
                    <ul>
                        <li>- 22 días de vacaciones</li>
                        <li>- 5 días por asuntos familiares</li>
                        <li>- 2 días de convenio</li>
                    </ul>
                </div>
                <div class="ui-fluid">
                    <div class="p-field">
                        <label for="tipoPermiso">Tipo de Permiso</label>
                        <p-dropdown id="tipoPermiso" [options]="permisos" [(ngModel)]="selectedPermiso"
                            placeholder="Seleccione" optionLabel="nombre" [showClear]="true"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="example-row">
                <div class="ui-fluid">
                    <div class="p-field">
                        <label for="dateFrom">Fecha desde</label>
                        <p-calendar id="dateFrom" [(ngModel)]="dateFrom" [showIcon]="true" [locale]="es"
                            dateFormat="dd/mm/yy" [touchUI]="true" [readonlyInput]="true"></p-calendar>
                    </div>
                </div>
                <div class="ui-fluid">
                    <div class="p-field">
                        <label for="dateTo">Fecha hasta</label>
                        <p-calendar id="dateTo" [(ngModel)]="dateTo" [showIcon]="true" [locale]="es"
                            dateFormat="dd/mm/yy" [touchUI]="true" [readonlyInput]="true">
                        </p-calendar>
                    </div>
                </div>
            </div>
            <div class="left-content">
                <p-button label="Solicitar" [routerLink]="['/login']" icon="fas fa-paper-plane" iconPos="left">
                </p-button>
            </div>
        </p-scrollPanel>
    </p-tabPanel>
    <p-tabPanel header="Calendario" id="tab-calendar">
        <br>
        <div class="example-row" id="container-summary">
            <div class="list-square">
                <div class="square square-accent"></div>
                Festivos
            </div>
            <div class="list-square">
                <div class="square square-muted"></div>
                Permisos
            </div>
            <div class="list-square">
                <div class="square square-primary"></div>
                Vacaciones
            </div>
            <div class="list-square">
                <div class="square square-red"></div>
                Bajas
            </div>
        </div>
        <br>
        
            <div class="example-row" id="container-clendars">
                <p-calendar *ngFor="let calendario of calendarios" [inline]="true" [locale]="es"
                    [defaultDate]="calendario.month">
                    <ng-template pTemplate="date" let-date>
                        <ng-container
                            *ngIf="calendario.permisosDays.includes(date.day); then permissionDate;else vacationsDate">
                        </ng-container>
                        <ng-template #permissionDate>
                            <span [ngStyle]="{backgroundColor: 'grey'}"
                                style="border-radius:10%; color: #ffffff;  padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                        </ng-template>
                        <ng-template #vacationsDate>
                            <ng-container *ngIf="calendario.vacacionesDays.includes(date.day) ;else festivosDate">
                                <span [ngStyle]="{backgroundColor: '#0e1e45'}"
                                    style="border-radius:10%; color: #ffffff; padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #festivosDate>
                            <ng-container *ngIf="calendario.festivosDays.includes(date.day);else bajasDate">
                                <span [ngStyle]="{backgroundColor: '#6f61fd'}"
                                    style="border-radius:10%; color: #ffffff;  padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #bajasDate>
                            <ng-container *ngIf="calendario.bajasDays.includes(date.day);else normalDate">
                                <span [ngStyle]="{backgroundColor: 'red'}"
                                    style="border-radius:10%; color: #ffffff; padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #normalDate>
                            <span [ngStyle]="{backgroundColor: 'inherit'}"
                                style="border-radius:10%; padding: .25em; width: 2em; height: 2em; display:block">{{date.day}}</span>
                        </ng-template>

                    </ng-template>
                </p-calendar>
            </div>
    </p-tabPanel>
</p-tabView>
-->