<div class="grid r-grid r-grid12-6" style="width:'100%';">
    <div class="g-w12field">
        <label for="incidencia">Incidencia</label>
        <input p-inputText id="incidencia" [disabled]="readonly" [(ngModel)]="incidencia.solicitud"
            placeholder="Describa la incidencia" />
    </div>

    <div class="g-w12field">
        <label for="dateFrom">Fecha</label>
        <p-calendar id="dateFrom" [(ngModel)]="incidencia.fechainicio" [showIcon]="true" [locale]="dateConfig"
            [touchUI]="true" [readonlyInput]="true" [disabled]="readonly" dateFormat="dd/mm/yy"></p-calendar>
    </div>

    <div class="g-w6field">
        <label for="dateFrom">Hora de inicio</label>
        <p-calendar id="dateFrom" [timeOnly]="true" [showTime]="true" [(ngModel)]="incidencia.horainicio"
            [showIcon]="true" [locale]="dateConfig" [touchUI]="true" [readonlyInput]="true" [disabled]="readonly"
            dateFormat="hh:mm"></p-calendar>
    </div>

    <div class="g-w6field">
        <label for="dateTo">Hora de fin</label>
        <p-calendar id="dateTo" [timeOnly]="true" [showTime]="true" [(ngModel)]="incidencia.horafin" [showIcon]="true"
            [locale]="dateConfig" [touchUI]="true" [readonlyInput]="true" [disabled]="readonly" dateFormat="dd/mm/yy">
        </p-calendar> 
    </div>

    <div class="g-w12field">
        <label for="observaciones">Observaciones</label>
        <textarea [disabled]="readonly" id="observaciones1" [rows]="5" [cols]="30" pInputTextarea
            [(ngModel)]="incidencia.obs" autoResize="autoResize"></textarea>
    </div>
    <div *ngIf="incidencia.contestacion!=''" class="g-w12field">
        <label for="observaciones">Observaciones</label>
        <textarea [disabled]="readonly" id="observaciones" [rows]="5" [cols]="30" pInputTextarea
            [(ngModel)]="incidencia.contestacion" autoResize="autoResize"></textarea>
    </div>
<div *ngIf="!readonly" style="display: none">
    <!--<div *ngIf="!readonly" class="g-w12field" class="ng-hide" style="display: none">-->
        <p-fileUpload [disabled]="readonly" fileLimit="1" auto="true" multiple="false" id="envioDoc" #envioDoc
            [showUploadButton]="false" chooseLabel="Adjuntar" maxFileSize="50000000"></p-fileUpload>
    </div>

    <div *ngIf="readonly && incidencia.doc!='' && incidencia.doc!=null" class="g-w12field">
        <a href="#" (click)='cbl.viewIDDoc(incidencia.doc)'>Documento adjunto</a>
    </div>


    <div class="g-w12field" *ngIf="readonly">

        <h3 class="title" position='center'>Añadir comentarios</h3>
        <div class="g-w12field">
            <label for="comment">Comentarios</label>
            <textarea id="comment" [rows]="3" [cols]="30" pInputTextarea [(ngModel)]="remarkBody.comment"
                autoResize="autoResize"></textarea>
        </div>

        <p-fileUpload mode="basic" #fileUpload name="demo[]" accept=".pdf,.jpg,.jped,.png,.gif" chooseLabel="Adjunto 1"
            customUpload="true" maxFileSize="1000000" (onSelect)="onBasicUpload($event , 'file1')"></p-fileUpload>

        <p-fileUpload mode="basic" #fileUpload2 name="demo[]" accept=".pdf,.jpg,.jped,.png,.gif" chooseLabel="Adjunto 2"
            customUpload="true" maxFileSize="1000000" (onSelect)="onBasicUpload($event , 'file2')"></p-fileUpload>

        <p-fileUpload mode="basic" #fileUpload3 name="demo[]" accept=".pdf,.jpg,.jped,.png,.gif" chooseLabel="Adjunto 3"
            customUpload="true" maxFileSize="1000000" (onSelect)="onBasicUpload($event , 'file3')"></p-fileUpload>

    </div>


    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w2">
                <p-button *ngIf="!readonly" label="Solicitar" (click)="solicitar()"></p-button>
                <p-button *ngIf="readonly" label="Guardar" (click)="saveComment()" style="margin-right:10px"></p-button>
                <p-button *ngIf="readonly" label="Cerrar" (click)="cerrar()"></p-button>
            </div>
        </div>
    </div>

    <!--sample remarks cards start-->
    <div class="g-w12field" *ngIf="readonly">
        <h3 class="title">Historial</h3>
        <p-card class="remark-card" *ngFor="let item of history">
            <span><strong>{{item?.timeStamp || 'NA'}}</strong></span>
            <div>{{item?.comment || 'NA'}}</div>

            <a (click)="showpdf(item.file1)" *ngIf="item.file1">File 1</a> &nbsp; <a *ngIf="item.file2"
                (click)="showpdf(item.file2)">File 2</a> &nbsp; <a (click)="showpdf(item.file3)" *ngIf="item.file3">File
                3</a>
        </p-card>
        <br>
            <br>
            <br>
            <br>
            <br>
    </div>
    <!--sample remarks cards end-->

</div>

<p-toast position='center'></p-toast>