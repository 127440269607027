import { Component, OnInit } from '@angular/core';
import { CBLService } from '../servicios/cbl.service';
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { MonthStrPipe } from '../libs/MonthStr.pipe';

export interface Nomina {
  fecha: Date;
  id: string;
  importe: number;
  documento: string;
}

@Component({
  selector: 'app-nomina',
  templateUrl: './nomina.component.html',
  styleUrls: ['./nomina.component.scss'],
})
export class NominaComponent implements OnInit {
  nominas: Nomina[] = [];
  nominasTemp: Nomina[] = [];
  yearArray:any[] = [];
  selectedYear:any;
  message = "Documentos del año";
  currentSearchYear:any;
  
  /*[
    {
      id: '1',
      fecha: new Date(),
      importe: 1500,
      documento: 'Junio 2020',
    },
    {
      id: '2',
      fecha: new Date(),
      importe: 1875,
      documento: 'Mayo 2020',
    },
    {
      id: '3',
      fecha: new Date(),
      importe: 1255,
      documento: 'Abril 2020',
    },
    {
      id: '4',
      fecha: new Date(),
      importe: 1575,
      documento: 'Marzo 2020',
    },
    {
      id: '5',
      fecha: new Date(),
      importe: 1575,
      documento: 'Junio 2020',
    },
    {
      id: '6',
      fecha: new Date(),
      importe: 1575,
      documento: 'Julio 2020',
    },
  ];*/

  constructor(private cbl:CBLService,private httpClient: HttpClient) {}

  ngOnInit(): void { 
      
    // let url2='salario?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    // +'&joins=documentos(S.idEmpleado=>D.idEmpleado)'
    // +'&fields=D.fechaEjercicio as fecha,S.importe,D.idDocumento'
    // +'&where=D.idEmpleado='+this.cbl.id_empleado+' and substr(D.fechaEjercicio,1,4)=S.ano and substr(D.fechaEjercicio,6,2)=S.mes'
    // +' and D.idSubMateria=260 and ( ((etiquetas=\'NOMINA\' or etiquetas like \'NOMINA,%25\') ))'
    // +'&order=D.fechaEjercicio DESC'
    // +'&tree=fecha(fecha,importe)->docs=>[idDocumento]&compacto=true'; 


    let url2='salario?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=documentos(S.idEmpleado=>D.idEmpleado)'
    +'&fields=D.fechaEjercicio as fecha,S.importe,D.idDocumento'
    +'&where=D.idEmpleado='+this.cbl.id_empleado+' and substr(D.fechaEjercicio,1,4)=S.ano and substr(D.fechaEjercicio,6,2)=S.mes'
    +' and D.idSubMateria=260 and ( ((etiquetas=\'NOMINA\' or etiquetas like \'NOMINA,%25\'  or etiquetas=\'RECIBO\' or etiquetas like \'RECIBO,%25\') ))'
    +'&order=D.fechaEjercicio DESC'
    +'&tree=fecha(fecha,importe)->docs=>[idDocumento]&compacto=true'; 
    
    
    //&compacto=true
    this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      this.nominas=r['resp'];
      this.nominasTemp = r['resp'];
      console.log("nominas.....",this.nominas)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 
    this.createYearPicker();
  }

  public click(ev)
  {
    //console.log(ev);
    this.cbl.viewDoc(ev);
  }

  createYearPicker(){
    let currentYear = new Date().getFullYear();
    let earliestYear = 2010;

    while (currentYear >= earliestYear) {
     this.yearArray.push({"year":currentYear})
      currentYear -= 1;
    }
  }

  searchBasedOnYear(){
    let tempData  = [];
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.currentSearchYear = ""+this.selectedYear["year"]
        this.nominasTemp.forEach(value =>{
          let yearFromDb:string = ""+value["fecha"];
          if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.currentSearchYear  = ''
      this.nominasTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.nominas = tempData;
  }
}
