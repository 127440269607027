import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CBLService } from '../servicios/cbl.service'
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Respuesta } from '../libs/respuesta';
import { formatDate } from '@angular/common';
import { Complaint, Documento } from '../libs/dataObjects'
import { MessageService, SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss'],
})
export class ComplaintComponent implements OnInit {

  dateConfig: any;
  complaint: Complaint = new Complaint();
  uploadedFiles: any[] = [];
  readonly: boolean = false;
  remarkBody = {
    comment: null,
    file1: null,
    file2: null,
    file3: null
  };
  _pdf: SafeUrl;
  privacy:any[]=[];
  type:any[]=[];
  status:any[]=[];
  cities:any[]=[];
  selectedCity:any;

  languages: SelectItem[] = [];
 selectedLanguage: any;
 applicant: any = {};
   countries: any[];
   dataLoaded = false;

   public allcomplaintcommunication: any = []; 

  @ViewChild('envioDoc') envioDoc: FileUpload;
  @ViewChild('fileUpload') fileUpload: any;
  @ViewChild('fileUpload2') fileUpload2: any;
  @ViewChild('fileUpload3') fileUpload3: any;


  @ViewChild('attachDocument') attachDocument: FileUpload;
  history: Respuesta;
  mes: string;
  selectedTeam: string;
  complaintStatus: string;


  constructor(public cbl: CBLService, private messageService: MessageService,
    private httpClient: HttpClient, public dialogService: DialogService, public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer) {

    let id = this.config.data.id;
    console.log(id);

    if (id) {
      //id=18;

      //alert(id);
      this.loadComplaint(id); 
      //this.getIncendias(id);
      this.readonly = true;
    }
    else {
      this.complaint = new Complaint();
     // this.complaint.fechainicio = new Date();
      this.readonly = false;
      
    }

    this.dateConfig = this.cbl.dateConfig;




  }

  ngOnInit(): void {

    this.mes = "Confidential";
    //this.selectedCity="Rome";
    this.selectedCity = {name:'Rome'};
    console.log("rrrrrrrrrrrrrrr",this.mes);


   this.showComplaintCommunication();

    this.countries = [
      {label:'Select Country', value:null}
  ];
  
  //setTimeout(() => {
  let result = [
  {id: 1, name: 'Pakistan'},
  {id: 2, name: 'India'},
  {id: 3, name: 'Sri Lanka'},
  ];
  
  result.forEach(function(country) {
  this.countries.push({label: country.name, value: country.name});
  }, this);
  
  this.dataLoaded = true;
  
  // }, 1000);
  
  
  
  //this.applicant = {country: 'India'};
  


  // this.privacy = [
  //   { label: 'Anonymous', value: 'Anonymous' },
  //   { label: 'Confidential', value: 'Confidential' },
   
  // ];

  this.privacy = [
    { label: 'ANONIMO', value: 'ANONIMO' },
    { label: 'CONFIDENCIAL', value: 'CONFIDENCIAL' },
   
  ];



  // this.type = [
  //   { label: 'Harassment', value: 'Harassment' },
  //   { label: 'Misbehavior', value: 'Misbehavior' },
  //   { label: 'Misappropriation of resources', value: 'Misappropriation of resources' },
  //   { label: 'Safety and occupational risks', value: 'Safety and occupational risks' },
  //   { label: 'Others', value: 'Others' },
   
  // ];


  this.type = [
    { label: 'Acoso', value: 'Acoso' },
    { label: 'Mala conducta', value: 'Mala conducta' },
    { label: 'Apropiación indebida de recursos', value: 'Apropiación indebida de recursos' },
    { label: 'Seguridad y riesgos laborales', value: 'Seguridad y riesgos laborales' },
    { label: 'Otros', value: 'Otros' },
   
  ];


  // this.status = [
  //   { label: 'SENT', value: 'SENT' },
  //   { label: 'READ', value: 'READ' },
  //   { label: 'ANALYSIS', value: 'ANALYSIS' },
  //   { label: 'INVESTIGATION', value: 'INVESTIGATION' },
  //   { label: 'COMMUNICATION TO THE COMPLAINT', value: 'COMMUNICATION TO THE COMPLAINT' },
  //   { label: 'RESOLVED', value: 'RESOLVED' },
  // ];

  this.status = [
    { label: 'ENVIADO', value: 'ENVIADO' }, 
    { label: 'LEIDO', value: 'LEIDO' },
    { label: 'ANALISIS', value: 'ANALISIS' },
    { label: 'INVESTIGACIÓN', value: 'INVESTIGACIÓN' },
    { label: 'COMUNICACIÓN AL DENUNCIANTE', value: 'COMUNICACIÓN AL DENUNCIANTE' },
    { label: 'RESUELTA', value: 'RESUELTA' },
  ];

  this.complaint.complaintStatus='ENVIADO';



this.applicant = {country: 'India'}; 


    

  }
  async getEmployeeName(id){
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,T.empleado as empleado'
    +'&where=E.idEmpleado='+id+' '
    +'&order=E.idEmpleado DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['empleado'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  async getClientName(id){
    let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idCliente,Cliente'
    +'&where=idCliente='+id+' '
    +'&order=idCliente DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['Cliente'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }

  
async getPersenInChargeName(id){
  let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
  +'&fields=idCliente,Cliente,encargadoDenuncias'
  +'&where=idCliente='+id+' '
  +'&order=idCliente DESC';  //&compacto=true

  //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

 // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

  //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
  var respuesta;
  await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
    let resp=r['resp'];
    respuesta=resp[0]['encargadoDenuncias'];
    //this.nominasTemp = r['resp'];
    console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
    /*this.total=0.0;
    this.nominas=this.nominas.map((nom)=>{
       if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
       return nom;
    });
    console.log(this.nominas,this.total);*/
  }); 

 return respuesta;
}









  showComplaintCommunication()
  {
    
		// let url="fields=E.idempleado as value,T.empleado,S.idsolicitud";
		// url=url+"&joins=trabajador,solicitudes(E.idempleado=>S.idempleado)";
		// // url=url+"&where=E.idcliente="+this.gds.id_cliente+" and year(S.fechainicio)='"+this.ejercicio+"'";
    
		// url=url+"&groupby=E.idempleado,T.empleado"
		// url=url+"&order=T.empleado";

    let url2='complaintcommunication?'
    +'&fields=idComplaintCommunication,idComplaint,complaintCommunication,communicationType,communicationByEmployeetId,communicationByClientId,communicationDate';
    url2+='&where=idComplaint="'+this.config.data.id+'"  ';
   
    url2+='&order=idComplaintCommunication ASC';
    console.log("url.....fff............",url2);
		// this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).subscribe((res)=>{
    //   console.log("data.......",res.resp);
		// 	this.allcomplaintcommunication=res.resp;      
      
		// });


    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then(async (r)=>{
      //console.log(r);
      console.log("vvvvvvvvvv",r.resp);
      for(let p of r.resp)
      {
        //let dias=0;
        // for(let d of p.permisos)
        // {
          // let cd=p.fechas.split(",").length;
          // p['dias']=cd; 
          // if (cd>1)
          //   p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
          // else
          //   p['periodo']=p.fechainicio;

          // if(p.horainicio!=null && p.horafin!=null)
          // {
          //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
          // }
          // if(p.estado!="RECHAZADA") dias+=cd;

         // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

         // p['periodo']=p.complaintDate;
        // }
        // let respEmp=this.getEmployeeName(p.idEmpleado);
        // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
        //  p['dias']=dias;
        //  p['employeeName']=await this.getEmployeeName(p.idEmpleado);

       // let respEmp=this.getEmployeeName(p.idEmpleado);
        //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
        // p['dias']=dias;
         if(p.communicationType=='Employee')
         {
          p['userName']=await this.getEmployeeName(p.communicationByEmployeetId);
         }
         else{ 
          p['userName']=await this.getClientName(p.communicationByClientId);
         }
         p['communicationDate']=formatDate(p.communicationDate,'dd/MM/yyyy HH:mm','en-US');
        //  p['complaintNodays']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(new Date(),'dd/MM/yyyy','en-US');

         
        
         
      }
      console.log(r.resp);
     // this.complaints=r.resp;
      this.allcomplaintcommunication=r.resp;
      
    });
  }
  async solicitar() {

    if (this.complaint.complaintDate == undefined || this.complaint.complaintDate == null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Complaint Date es obligatorio!' });
      return;
    }

    if (this.complaint.complaintPrivacy == "") { 
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Complaint Privacy es obligatorio!' });
        return;
      }

      if (this.complaint.complaintType == "") {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Complaint Type es obligatorio!' });
        return;
      }

      // if (this.complaint.complaintStatus == "") {
      //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Complaint Status es obligatorio!' });
      //   return;
      // }


      if (this.complaint.complaintDesc == "") {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Complaint Desc es obligatorio!' });
        return;
      }


    // if (this.complaint.complaintDate == "") {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Complaint es obligatorio!' });
    //   return;
    // }
    // if (this.complaint.fechainicio == undefined || this.complaint.fechainicio == null) {
    //   this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Fecha es obligatorio!' });
    //   return;
    // }
    // if (this.complaint.horainicio != undefined || this.complaint.horafin != undefined) {
    //   if (this.complaint.horainicio > this.complaint.horafin || this.complaint.horainicio == undefined || this.complaint.horafin == undefined) {
    //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Hora de fin debe ser mayor que hora de inicio!' });
    //     return;
    //   }
    // }

    // console.log(this.envioDoc);
    // if (this.envioDoc.files.length > 0) {
    //   let meta = new Documento({
    //     idEmpleado: this.cbl.id_empleado,
    //     idCliente: this.cbl.id_cliente,
    //     descripcion: this.envioDoc.files[0].name + " (" + this.cbl.empleado + ")",
    //     idMateria: 5, idSubMateria: 36
    //   });
    //   await this.cbl.send_documentos(this.envioDoc.files, meta).then((r) => {
    //     console.log(r);
    //     this.complaint.doc = r[0];
    //   });
    // }


    // this.complaint.fechafin = this.complaint.fechainicio;
    // this.complaint.estado = 'SOLICITUD';
    // this.complaint.idEmpleado = this.cbl.id_empleado;
    // this.cbl.insertSolicitud(this.complaint).then()
    // {
   
    // }



    console.log(this.attachDocument);
    if (this.attachDocument.files.length > 0) {
      let meta = new Documento({
        idEmpleado: this.cbl.id_empleado,
        idCliente: this.cbl.id_cliente,
        descripcion: this.attachDocument.files[0].name + " (" + this.cbl.empleado + ")",
        idMateria: 5, idSubMateria: 36
      });
      await this.cbl.send_documentos(this.attachDocument.files, meta).then((r) => {
        console.log(r);
        this.complaint.complaintAttachDocument = r[0];
      });
    }

    this.complaint.idEmpleado = this.cbl.id_empleado;
    this.complaint.complaintDate = this.complaint.complaintDate;
    this.complaint.complaintPrivacy = this.complaint.complaintPrivacy;
    this.complaint.complaintType = this.complaint.complaintType;
    // this.complaint.complaintStatus = this.complaint.complaintStatus;
    this.complaint.complaintStatus = "ENVIADO";
    this.complaint.currentDate = new Date();
    this.complaint.complaintDesc = this.complaint.complaintDesc;
    this.complaint.complaintAttachDocument = this.complaint.complaintAttachDocument;
    this.cbl.insertComplaint(this.complaint).then()
    {
     
       this.ref.close(true);
    }


  }





   saveComment() {



    // this.httpClient.put(this.gds.urlBaseDL+'clientes/'+this.gds.id_cliente,{idComplaint:this.complaint.idComplaint,complaintStatus:this.complaint.complaintStatus,complaintCommunication:this.complaint.complaintCommunication,complaintHandledClientId:this.complaint.complaintHandledClientId}).subscribe((res)=>{
    //   console.log(res);
    //   // this.datosCliente=Object.assign({},this.datosClienteLocal);
    //   // this.messageService.add({severity:'success', summary:'', detail:'Datos actualizados!'});
    // });

   //alert("idComplaint:"+this.complaint.idComplaint+",complaintStatus:"+this.complaint.complaintStatus+",complaintCommunication:"+this.complaint.complaintCommunication+",complaintHandledClientId:"+this.complaint.complaintHandledClientId);

// this.httpClient.put(this.cbl.urlBaseDL + 'complaint/' + this.complaint.idComplaint, {idComplaint:this.complaint.idComplaint,complaintStatus:this.complaint.complaintStatus,complaintCommunication:this.complaint.complaintCommunication,complaintHandledClientId:this.complaint.complaintHandledClientId}).toPromise();




        // this.httpClient.post(this.cbl.urlBaseDL + 'complaintcommunication/' + this.complaint.idComplaint, {
        // idComplaint:this.complaint.idComplaint,
        // complaintCommunication:this.complaint.complaintCommunication,
        // communicationType:"Employee",
        // communicationByEmployeetId:this.cbl.id_empleado,
        // communicationByClientId:"0",
        // communicationDate:formatDate(new Date(), 'yyyy-MM-dd', 'en-US'),
        // }).toPromise().then((r) => {
        //   this.ref.close(true);
        // });

        if (this.complaint.complaintCommunication == "") {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Complaint nuevo mensaje es obligatorio!' });
            return;
          }

         
        this.httpClient.post(this.cbl.urlBaseDL + 'client_project_employee_work/' + this.complaint.idComplaint, {idComplaint:this.complaint.idComplaint,complaintCommunication:this.complaint.complaintCommunication,communicationType:"Employee",communicationByEmployeetId:this.cbl.id_empleado,communicationByClientId:"0",communicationDate:formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US')}).toPromise().then((p) => {
          if (p) {

          this.ref.close(true);

          }
          });

    
  }











  complaintAttachDocument(complaintAttachDocument: any) {
    throw new Error('Method not implemented.');
  }

  cerrar() {
    this.ref.destroy();
  }



  loadComplaint(id) {
    let url2 = 'complaint?'
    +'&fields=idComplaint,idEmpleado,complaintDate,complaintPrivacy,complaintType,complaintAttachDocument,complaintDesc,complaintStatus,complaintCommunication,complaintHandledClientId'
    + '&where=idComplaint=' + id;

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + url2).toPromise().then(async (r) => {
      console.log(r.resp);
      // r.resp[0].horainicio = r.resp[0].horainicio.substr(0, 5);
      // r.resp[0].horafin = r.resp[0].horafin.substr(0, 5);
      // r.resp[0].fechainicio = formatDate(r.resp[0].fechainicio, 'dd/MM/yyyy', 'en-US');
      r.resp[0].idComplaint = r.resp[0].idComplaint;
      r.resp[0].idEmpleado = r.resp[0].idEmpleado;
      r.resp[0].currentDate = r.resp[0].currentDate;
      r.resp[0].currentDate = r.resp[0].currentDate;
      r.resp[0].complaintPrivacy = r.resp[0].complaintPrivacy;
      r.resp[0].complaintType = r.resp[0].complaintType;
      r.resp[0].complaintDesc = r.resp[0].complaintDesc;
      r.resp[0].complaintAttachDocument = r.resp[0].complaintAttachDocument;
      r.resp[0].complaintStatus = r.resp[0].complaintStatus;
      // r.resp[0].complaintCommunication = r.resp[0].complaintCommunication;
      r.resp[0].complaintCommunication = "";
      r.resp[0].complaintDate = formatDate(r.resp[0].complaintDate, 'dd/MM/yyyy', 'en-US');
      r.resp[0].complaintHandledClientId = r.resp[0].complaintHandledClientId;
      if(r.resp[0].complaintHandledClientId!=0)
      {
        
        r.resp[0].complaintHandledClientName = await this.getPersenInChargeName(r.resp[0].complaintHandledClientId); 
      }
      else{
        r.resp[0].complaintHandledClientName = '';
      }
      
       this.complaint = new Complaint(r.resp[0]);
      //this.complaint = r.resp[0];
      //this.complaint.fechafin = this.complaint.fechainicio;

      console.log("aaaaaaaaaaaaaaaaaaaaaaa",this.complaint);
    });
  }

  /**functions to cpature comments and upload files start */
  onBasicUpload(inputData, key) {
    for (const file of inputData.currentFiles) {
      const dataset = this.readFile(file, key);
    }
  }

  private readFile(file: File, key) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.remarkBody[key] = JSON.stringify(reader.result);
    };
  }

  saveComment_old() {
    this.httpClient.post<Respuesta>(this.cbl.urlBaseDL + 'incidenciaremark/' + this.config.data.id, this.remarkBody).toPromise().then((r) => {
      if (r) {
        this.remarkBody.comment = null;
        this.remarkBody.file1 = null;
        this.remarkBody.file2 = null;
        this.remarkBody.file3 = null;
        this.getIncendias(this.config.data.id);
        this.clear();
      }
    });
  }
  /**functions to cpature comments and upload files end */
  getIncendias(id) {
    this.httpClient.post<Respuesta>(this.cbl.urlBaseDL + 'getincidenciaremark/' + id, {}).toPromise().then((r) => {
      this.history = r;
    });
  }

  showpdf(file) {
    let fileArray:any[] = file.split(",");
      const [,type] = fileArray[0].split(';')[0].split('/');
      const linkSource = file;
      const downloadLink = document.createElement("a");
      const fileName = "document."+type;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
  }

  clear() {
    this.fileUpload.clear();
    this.fileUpload2.clear();
    this.fileUpload3.clear();

    this.attachDocument.clear();
    
  }
}
