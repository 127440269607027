
<div class="complaints-header">
    <div class="center">
        <h3 class="title">Portal de denuncias</h3>
    </div>
    <div class="right">
        <div style=" display:flex;  flex-direction: row;">
            <p-dropdown
                [options]="yearArray"
                placeholder="Seleccionar año"
                optionLabel="year"
                [showClear]="true"
                [(ngModel)]="selectedYear"
                [style]="{'font-size':'10px'}"
                #dropDownThing
            >
            </p-dropdown>
            <button class="button" (click)="searchBasedOnYear()" style="margin-left: 4px; cursor: pointer;">Buscar</button>
        </div>
    </div>
</div>
<div class="example-column">
    <p-table [value]="complaints" selectionMode="single" [(selection)]="selectedComplaint"
        [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando {first} de {totalPages}" (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="header">
            <tr>
                <th>Nombre</th>
                <th>Fecha denuncia</th>
                <th>Privacidad</th>
                <th>Tipo de denuncia</th>
                <!-- <th>Comunicación interna</th> -->
                <th>Doc. adjunto</th>
                <th>Estado</th>
                <th>Persona a cargo</th>
                <th width="10%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-complaint>
            <tr [pSelectableRow]="complaint">
                
                <td>{{complaint.employeeName}} </td>
                <td>{{complaint.complaintDate}}</td>
                <td>{{complaint.complaintPrivacy}}</td>
                <td>{{complaint.complaintType}}</td>
                <!-- <td>{{complaint.complaintCommunication}}</td> -->
                <td><a href="#" *ngIf=" complaint.complaintAttachDocument!='' && complaint.complaintAttachDocument!=null"  (click)='cbl.viewIDDoc(complaint.complaintAttachDocument)'>Documento adjunto</a></td>
                <td>{{complaint.complaintStatus}}</td>
                <td>{{complaint.complaintInCharge}}</td>
                <td>
                    <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-eye" class="p-button-outlined"
                    (click)="selectComplaint(complaint.idComplaint)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>    
</div>

<div class="grid r-grid r-grid12-6" style="width:'100%';">
    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w1"><p-button label="Nueva denuncia" (click)="nueva()" icon="fas fa-paper-plane" iconPos="left"></p-button></div>
            <br>
            <br>
            <br>
            <br>
            <br>
        </div>
    </div>
</div>
