import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import {CBLService} from '../servicios/cbl.service'
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { Utilidades } from '../libs/utilidades';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.scss'],
})
export class DocumentosComponent implements OnInit {
  files: TreeNode[];
  arbol: any[];
  arbol1:any[];
  yearArray:any[]=[];
  selectedYear:any;
  constructor(public cbl:CBLService,private httpClient: HttpClient) {}

  ngOnInit(): void {
    this.createYearPicker();
    this.files = [
      {
        key: '0',
        label: 'Convenios',
        collapsedIcon: 'fas fa-folder',
        expandedIcon: 'fas fa-folder-open',
      },
      {
        key: '1',
        label: 'Contratos',
        collapsedIcon: 'fas fa-folder',
        expandedIcon: 'fas fa-folder-open',
      },
      {
        key: '2',
        label: 'Prórrogas',
        collapsedIcon: 'fas fa-folder',
        expandedIcon: 'fas fa-folder-open',
      },
      {
        key: '3',
        label: 'Gestión',
        collapsedIcon: 'fas fa-folder',
        expandedIcon: 'fas fa-folder-open',
        children: [
          {
            key: '3-0',
            label: 'Altas',
            collapsedIcon: 'fas fa-folder',
            expandedIcon: 'fas fa-folder-open',
          },
          {
            key: '3-1',
            label: 'Bajas',
            collapsedIcon: 'fas fa-folder',
            expandedIcon: 'fas fa-folder-open',
            children: [
              {
                key: '3-1-0',
                label: 'Informe.docx',
                icon: 'fas fa-file',
              },
            ],
          },
        ],
      },
    ];

  this.applyFilter();
}


applyFilter()
{

  // var mat=this.materia;
  // var ej=this.ejercicio;
  // if(mat==null) mat=0;
  // if(ej==null) ej=0;

  // console.log(mat);
  
  // let filtro:string;
  // if (mat!=0)
  //   filtro="D.idMateria="+mat;
  // else
  //   filtro="D.idSubMateria<>0 and D.idSubMateria!=16 and D.idSubMAteria!=18";

  // if(this.documento.length>0)
  //   filtro+=" AND D.descripcion like '%"+this.documento+"%'";
  let currentYear = formatDate(Date.now(), 'Y', 'en-US');
    var params={
    fields:"idDocumento,descripcion,idMateria,idSubMateria,fechaEntrada,tipoFecha,fechaEjercicio",
    joins:"materias(idMateria),materias(idSubMateria)",
    order:"M.materia,M2.materia,fechaEjercicio DESC,fechaIncorporacion",
    //where:"idCliente="+this.gds.id_cliente+" AND D.idSubMateria<>0 and D.idSubMateria!=16 and D.idSubMAteria!=18 AND seguridad=1 "+((ej!=0)?" AND (M.permanente=10 OR ejercicio="+ej+")":""),
    where:"idCliente="+this.cbl.id_cliente+" AND seguridad=1 AND idSubMateria!=0 AND LEFT(fechaEjercicio , 4)  = "+currentYear+" AND idEmpleado="+this.cbl.id_empleado,
    tree:"materias.materia(idMateria,materias.materia),materias2.materia(idSubMateria)=>idDocumento,descripcion,fechaEntrada,tipoFecha,fechaEjercicio",
  };
  //console.log(params);
  //var vlo=btoa(JSON.stringify(params));
  //this.httpClient.get<any>(this.gds.urlBaseDL+"documentos/?vlo="+Utilidades.packVLO(params)).subscribe((res)=>{
  this.httpClient.post<any>(this.cbl.urlBaseDL+"documentos/query",{'vlo':Utilidades.packVLO(params)}).subscribe((res:any)=>{

    //console.log("RECIBIDO:",res);
    console.log("RECIBIDO resp:",res.resp);
    
    res=res.resp;
    
    for (var rama of res)
    {
      rama['collapsedIcon']="fa fa-folder";
      rama['expandedIcon']="fa fa-folder-open";
      rama['type']="rama";
      for (var subrama of rama['children'])
      {
        subrama['collapsedIcon']="fa fa-folder";
        subrama['expandedIcon']="fa fa-folder-open";
        subrama['type']="rama";
        for (var item of subrama['children'])
        {
          item['type']="doc";
          item['icon']="fa fa-file";
          item['label']=item['descripcion'];
          item['key']=item['idDocumento'];
          //item['data']={'fechaEntrada':item['fechaEntrada'].substr(0,10),'tipoFecha':item['tipoFecha'],'fechaEjercicio':item['fechaEjercicio']}
          item['data']={'fechaEjercicio':item['fechaEjercicio']};
        }
      }
    }
    //console.log("PROCESADO:",res);
    this.arbol=res;
    this.arbol1=JSON.parse(JSON.stringify(this.arbol));
  });
}

searchBasedOnYear(){


   // var mat=this.materia;
  // var ej=this.ejercicio;
  // if(mat==null) mat=0;
  // if(ej==null) ej=0;

  // console.log(mat);
  
  // let filtro:string;
  // if (mat!=0)
  //   filtro="D.idMateria="+mat;
  // else
  //   filtro="D.idSubMateria<>0 and D.idSubMateria!=16 and D.idSubMAteria!=18";

  // if(this.documento.length>0)
  //   filtro+=" AND D.descripcion like '%"+this.documento+"%'";

  console.log("pppppppppp");
 console.log(this.selectedYear);
 console.log("ssssssssss");
 if(this.selectedYear==null || this.selectedYear==undefined )
 {
  //let currentYear='';
 
  var params={
    fields:"idDocumento,descripcion,idMateria,idSubMateria,fechaEntrada,tipoFecha,fechaEjercicio",
    joins:"materias(idMateria),materias(idSubMateria)",
    order:"M.materia,M2.materia,fechaEjercicio DESC,fechaIncorporacion",
    //where:"idCliente="+this.gds.id_cliente+" AND D.idSubMateria<>0 and D.idSubMateria!=16 and D.idSubMAteria!=18 AND seguridad=1 "+((ej!=0)?" AND (M.permanente=10 OR ejercicio="+ej+")":""),
    where:"idCliente="+this.cbl.id_cliente+" AND seguridad=1 AND idSubMateria!=0  AND idEmpleado="+this.cbl.id_empleado,
    tree:"materias.materia(idMateria,materias.materia),materias2.materia(idSubMateria)=>idDocumento,descripcion,fechaEntrada,tipoFecha,fechaEjercicio",
  };


 }
 else{

  var params={
    fields:"idDocumento,descripcion,idMateria,idSubMateria,fechaEntrada,tipoFecha,fechaEjercicio",
    joins:"materias(idMateria),materias(idSubMateria)",
    order:"M.materia,M2.materia,fechaEjercicio DESC,fechaIncorporacion",
    //where:"idCliente="+this.gds.id_cliente+" AND D.idSubMateria<>0 and D.idSubMateria!=16 and D.idSubMAteria!=18 AND seguridad=1 "+((ej!=0)?" AND (M.permanente=10 OR ejercicio="+ej+")":""),
    where:"idCliente="+this.cbl.id_cliente+" AND seguridad=1 AND idSubMateria!=0   AND LEFT(fechaEjercicio , 4)  = "+this.selectedYear.year+"  AND idEmpleado="+this.cbl.id_empleado,
    tree:"materias.materia(idMateria,materias.materia),materias2.materia(idSubMateria)=>idDocumento,descripcion,fechaEntrada,tipoFecha,fechaEjercicio",
  };

 }

 

  console.log(params);
  //var vlo=btoa(JSON.stringify(params));
  //this.httpClient.get<any>(this.gds.urlBaseDL+"documentos/?vlo="+Utilidades.packVLO(params)).subscribe((res)=>{
  this.httpClient.post<any>(this.cbl.urlBaseDL+"documentos/query",{'vlo':Utilidades.packVLO(params)}).subscribe((res:any)=>{

    //console.log("RECIBIDO:",res);
    console.log("RECIBIDO resp:",res.resp);
    
    res=res.resp;
    
    for (var rama of res)
    {
      rama['collapsedIcon']="fa fa-folder";
      rama['expandedIcon']="fa fa-folder-open";
      rama['type']="rama";
      for (var subrama of rama['children'])
      {
        subrama['collapsedIcon']="fa fa-folder";
        subrama['expandedIcon']="fa fa-folder-open";
        subrama['type']="rama";
        for (var item of subrama['children'])
        {
          item['type']="doc";
          item['icon']="fa fa-file";
          item['label']=item['descripcion'];
          item['key']=item['idDocumento'];
          item['fechaEjercicio']=item['fechaEjercicio'];
          //item['data']={'fechaEntrada':item['fechaEntrada'].substr(0,10),'tipoFecha':item['tipoFecha'],'fechaEjercicio':item['fechaEjercicio']}
          item['data']={'fechaEjercicio':item['fechaEjercicio']};
        }
      }
    }
    //console.log("PROCESADO:",res);
    this.arbol=res;
    this.arbol1=JSON.parse(JSON.stringify(this.arbol));
  });
   
 }
searchBasedOnYear_Backup(){
 this.arbol=JSON.parse(JSON.stringify(this.arbol1));;
 //this.arbol= this.arbol[0]?.children[0]?.children?.filter((date:any)=>date.fechaEjercicio.split("-")[0]==this.selectedYear.year);
 //this.arbol=
 console.log("mainnnn"+this.arbol);
 this.arbol[0]?.children.map((data:any,i:any)=>{

  console.log("this is the selected year: " + this.selectedYear.year);

  

  let aaa = JSON.stringify(data.children?.filter((date:any)=>date.fechaEjercicio.split("-")[0]));
  let bbb = JSON.stringify(data.children?.filter((date:any)=>date.fechaEjercicio.split("-")[0]));
  //console.log("this is the value to compare from previous json response: " + aaa);
  console.log("****************************** iteration **************************** ")


  console.log(data.children);
  console.log("****************************** iteration **************************** ")

  //return data.children = data.children?.filter((date:any)=>date.fechaEjercicio.split("-")[0]==this.selectedYear.year);
  console.log(data.children?.filter((date:any)=>date.fechaEjercicio.split("-")[0]==this.selectedYear.year));
  return data.children = data.children?.filter((date:any)=>date.fechaEjercicio.split("-")[0]==this.selectedYear.year);


 });
}
createYearPicker(){
  let currentYear = new Date().getFullYear();
  let earliestYear = 2010;

  while (currentYear >= earliestYear) {
   this.yearArray.push({"year":currentYear})
    currentYear -= 1;
  }
}
}