import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'MonthStr'})
export class MonthStrPipe implements PipeTransform {
  private meses=['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'];

  transform(value: string): string {
      console.log(value);
    let ano=value.substr(0,4);
    let mes=value.substr(5,2);
    return this.meses[Number(mes)-1]+" de "+ano;
  }
}