import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import {CBLService} from '../servicios/cbl.service'

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class PerfilComponent implements OnInit {

  //pwd_actual:string;
  pwd1:string="";
  pwd2:string="";

  constructor(
    //private confirmationService: ConfirmationService,
    private messageService: MessageService,public cbl:CBLService
  ) {}

  ngOnInit(): void {}

  // openDialog() {
  //   this.confirmationService.confirm({
  //     message: '¿Está seguro de guardar los cambios?',
  //     acceptLabel: 'Confirmar',
  //     rejectLabel: 'Cancelar',
  //     acceptIcon: 'icon-none',
  //     rejectIcon: 'icon-none',
  //     rejectButtonStyleClass: 'ui-button-danger',
  //     accept: () => {
  //       this.confirmarCambios();
  //     },
  //     reject: () => {
  //       this.cancelarCambios();
  //     },
  //   });
  // }

  // confirmarCambios() {
  //   this.messageService.add({
  //     severity: 'info',
  //     summary: 'Completado!',
  //     detail: 'Han sido guardados los cambios',
  //   });
  // }
  // cancelarCambios() {
  //   this.messageService.add({
  //     severity: 'warn',
  //     summary: 'Atención!',
  //     detail: 'No se han guardado los cambios',
  //   });
  // }

  changepwd()
  {
      if (this.pwd1!=this.pwd2)
      {
        this.messageService.add({severity: 'error',summary: 'Error!',detail: 'Ambas contraseñas deben ser iguales!'});
        return;
      }

      if (this.pwd1.length<6)
      {
        this.messageService.add({severity: 'error',summary: 'Error!',detail: 'Como mínimo debe usar contraseñas de 6 caracteres!'});
        return;
      }

      this.cbl.updatepwd(this.cbl.id_empleado,this.pwd1).then((r)=>{
        console.log(r);
      });
      this.messageService.add({severity: 'info',summary: 'Completado!',detail: 'La contraseña ha sido cambiada'});
  }
}
