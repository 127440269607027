import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CBLService } from './servicios/cbl.service';

import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs, 'es');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { LoginComponent } from './login/login.component';

/**PRIMENG MODULES */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { PasswordModule } from 'primeng/password';

import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { TreeModule } from 'primeng/tree';
import { FieldsetModule } from 'primeng/fieldset';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {FileUploadModule} from 'primeng/fileupload';
import {InputNumberModule} from 'primeng/inputnumber';
import {MessagesModule} from 'primeng/messages';
import {ToastModule} from 'primeng/toast';
import {DynamicDialogModule,DialogService,DynamicDialogRef,DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ConfirmDialogModule} from 'primeng/confirmdialog';

import { HttpClientModule ,HttpClient,HttpHeaders,HttpParams,HttpResponse,HttpHandler } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RespuestaInterceptor } from './servicios/respuesta.interceptor';
import { SolicitudInterceptor } from './servicios/solicitud.interceptor';

import { registerLocaleData } from '@angular/common';
import { FichajeComponent } from './fichaje/fichaje.component';
import { FacialComponent } from './facial/facial.component';
import { EntrenamientoComponent } from './entrenamiento/entrenamiento.component';
import { PermisosComponent } from './permisos/permisos.component';
import { PermisoscadaComponent } from './permisoscada/permisoscada.component';
import { DocumentosComponent } from './documentos/documentos.component';
import { IncidenciasComponent } from './incidencias/incidencias.component';
import { JornadaComponent } from './jornada/jornada.component';
import { NominaComponent } from './nomina/nomina.component';
import { ComunicacionComponent } from './comunicacion/comunicacion.component';
import { PerfilComponent } from './perfil/perfil.component';
import { NotificacionComponent } from './notificacion/notificacion.component';
import { VisitasComponent } from './visitas/visitas.component';
import {MessageService} from 'primeng/api';
import {CommonModule} from '@angular/common';
import { MonthStrPipe } from './libs/MonthStr.pipe';
import {TabMenuModule} from 'primeng/tabmenu';
import { JornadasMainComponent } from './jornadas-main/jornadas-main.component';
import { HomeComponent } from './home/home.component';
import {DialogModule} from 'primeng/dialog';
import { VisitaComponent } from './visitas/visita/visita.component';
import { IncidenciaComponent } from './incidencia/incidencia.component';
import { VisitorsService } from './servicios/visitors.service';
/// Code for REcaptcha
import { NgxCaptchaModule } from 'ngx-captcha';
 /// Code for REcaptcha End
 import {CardModule} from 'primeng/card';
import { HistoryComponent } from './history/history.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { ComplaintsComponent } from './complaints/complaints.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { ClientprojectdetailComponent } from './clientprojectdetail/clientprojectdetail.component';
import { ClientprojectdetailmainComponent } from './clientprojectdetailmain/clientprojectdetailmain.component';

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    LoginComponent,
    FichajeComponent,
    FacialComponent,
    EntrenamientoComponent,
    PermisosComponent,
    PermisoscadaComponent,
    DocumentosComponent,
    IncidenciasComponent,
    JornadaComponent,
    NominaComponent,
    ComunicacionComponent,
    PerfilComponent,
    NotificacionComponent,VisitasComponent,MonthStrPipe,
    JornadasMainComponent, HomeComponent, VisitaComponent, IncidenciaComponent, HistoryComponent, ChangepasswordComponent,
    ComplaintsComponent,
    ComplaintComponent,
    ClientprojectdetailComponent,
    ClientprojectdetailmainComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    ButtonModule,
    SplitButtonModule,
    InputTextModule,
    TableModule,
    PasswordModule,
    ProgressBarModule,
    TabViewModule,
    CalendarModule,
    DropdownModule,
    TreeModule,
    FieldsetModule,
    InputTextareaModule,
    ScrollPanelModule,
    FileUploadModule,
    InputNumberModule,
    MessagesModule,
    ToastModule,
    HttpClientModule, 
    ConfirmDialogModule,
    ConfirmDialogModule,BrowserAnimationsModule,
    FormsModule,ToastModule,TabMenuModule,DialogModule,
    DynamicDialogModule,
    /// Code for REcaptcha
    ReactiveFormsModule,
    NgxCaptchaModule,
    /// Code for REcaptcha End
    CardModule,
  ],
  providers: [HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RespuestaInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SolicitudInterceptor,
      multi: true
    },{ provide: LOCALE_ID, useValue: 'es' },
      MessageService,CBLService,
      DialogService,DynamicDialogRef,DynamicDialogConfig,
    VisitorsService],
  bootstrap: [AppComponent],
  entryComponents: [
    FichajeComponent
]
})
export class AppModule {}
