<div class="main-new" style="border-bottom: 1px solid #cccbcb;">
    <div>
        

            <ul>
                <li><b>Nombre: </b><span style="color: black">{{employeeName}}</span></li>
                <li><b>Empresa: </b><span style="color: black">{{companyName}}</span></li>
                <li><b>Fecha: </b><span style="color: black">{{currentDate}}</span></li>
                <li><b>Año: </b><span style="color: black">{{currentSelectedYear}}</span></li>
            </ul>
      
      
    </div>

</div>
<div class="main-new" >
    
    <div>
        <div >
            <h3 class="title" style="text-align: center !important;">Jornadas</h3>
            
        </div>
    </div>
    <div>
        <div style=" display:flex;  flex-direction: row;" *ngIf="currentActiveTab != 'Fichajes'">
            <p-dropdown
                [options]="yearArray"
                placeholder="Seleccionar año"
                optionLabel="year"
                [showClear]="true"
                [(ngModel)]="selectedYear"
                [style]="{'font-size':'10px'}"
                #dropDownThing
            >
            </p-dropdown>
           <!-- <p-button label="Search" (click)="searchBasedOnYear()" style="margin-left: 4px;"></p-button>-->
            <button class="button" (click)="searchBasedOnYear()" style="margin-left: 4px;"  >Buscar</button>
        </div>
    </div> 
    
</div>

<div class="main-new" >
    
    
    <div class="center">
        <div >
           
            <p-tabMenu [model]="items" [activeItem]="item"></p-tabMenu>
        </div>
    </div> 
    
</div>




































<!-- <div class="main" *ngIf="currentActiveTab == 'Fichajes'">


   

    <div class="jornadas_section">
      <div class="jornadas_nav">
        <div class="">
            <ul>
                <li><b>Nombre: </b><span style="color: black">{{employeeName}}</span></li>
                <li><b>Empresa: </b><span style="color: black">{{companyName}}</span></li>
                <li><b>Fecha: </b><span style="color: black">{{currentDate}}</span></li>
                <li><b>Año: </b><span style="color: black">{{currentSelectedYear}}</span></li>
            </ul>
      
    
        </div>
    
      </div>
      
      <div class="jornadas_article">
      
        <div class="">
            <div >
                <h3 class="title" >Jornadas</h3>
                <p-tabMenu [model]="items" [activeItem]="item"></p-tabMenu>
            </div>
        </div> 
        
        
      </div>
  
      <div class="jornadas_article1">
      
        <div>
            <div style=" display:flex;  flex-direction: row;">
                <div style="width: 10px;">
                </div>
            </div>
        </div>

        
        </div>
  
  
    </div>




</div> -->






<!-- <div class="main-new" *ngIf="currentActiveTab != 'Fichajes'">



    <div class="jornadas_section">
        <div class="jornadas_nav">
            <div>
        

                <ul>
                    <li><b>Nombre: </b><span style="color: black">{{employeeName}}</span></li>
                    <li><b>Empresa: </b><span style="color: black">{{companyName}}</span></li>
                    <li><b>Fecha: </b><span style="color: black">{{currentDate}}</span></li>
                    <li><b>Año: </b><span style="color: black">{{currentSelectedYear}}</span></li>
                </ul>
          
          
        </div>
        </div>
        
        <div class="jornadas_article">
        
            <div>
                <div >
                    <h3 class="title" >Jornadas</h3>
                    <p-tabMenu [model]="items" [activeItem]="item"></p-tabMenu>
                </div>
            </div>


        </div>
      
        <div class="jornadas_article1">
        
            <div>
                <div style=" display:flex;  flex-direction: row;">
                    <p-dropdown
                        [options]="yearArray"
                        placeholder="Seleccionar año"
                        optionLabel="year"
                        [showClear]="true"
                        [(ngModel)]="selectedYear"
                        [style]="{'font-size':'10px'}"
                        #dropDownThing
                    >
                    </p-dropdown>
                    <p-button label="Search" (click)="searchBasedOnYear()" style="margin-left: 4px;"></p-button>
                    <button class="button" (click)="searchBasedOnYear()" style="margin-left: 4px;"  >Buscar</button>
                </div>
            </div>


          </div>
      
      
      </div>



    
</div> -->















<router-outlet></router-outlet>




<!--
<p-scrollPanel [style]="{width: '100%'}">
    <div class="example-column">
        <p-calendar [(ngModel)]="dateSelector" [locale]="es" [showIcon]="true" view="month" dateFormat="mm/yy"
            [yearNavigator]="true" yearRange="2000:2030" [readonlyInput]="true" inputId="monthpicker"></p-calendar>

        <p-table [value]="jornadas" [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5"
            [showCurrentPageReport]="true" currentPageReportTemplate="Mostrando {first} de {totalPages}">
            <ng-template pTemplate="header">
                <tr>
                    <th>Fecha</th>
                    <th>Saldo (horas)</th>
                    <th>Situación</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-jor>
                <tr>
                    <td>{{jor.fecha}}</td>
                    <td>{{jor.saldo}}</td>
                    <td>{{jor.situacion}} </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="2">Saldo acumulado del mes en horas</td>
                    <td>136:10</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-scrollPanel>
--> 



















<div class="main-new" style="border-top: 1px solid #cccbcb; padding-top: 20px;">
    <div>
        <div >
            <h3 class="title" style="text-align: center !important;">Proyecto</h3>
            
        </div>
    </div>

</div>


<div class="example-column">
    <p-table [value]="clientprojects" selectionMode="single" [(selection)]="selectedClientproject"
        [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando {first} de {totalPages}" (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="header">
            <tr>
                <th>Proyecto </th>
                <th>Asignado a:</th>
                <th>Fecha inicio</th> 
                <th>Fecha fin</th>
                <th>Total (horas)</th>
                <th>Horas trabajadas</th>
                <th width="5%">Informes</th>
                
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-clientproject>
            <tr [pSelectableRow]="clientproject">
                
                <td >
                    <!-- {{clientproject.project_name}} -->
                    <a  (click)="selectClientprojectDetail(clientproject.idClient_project,'detail')">{{clientproject.project_name}}</a>
                
                </td>
                <td>{{clientproject.employeeName}}</td>
                <!-- <td>
                    <ul> 
                    <li *ngFor="let item of clientproject.employeeName; index as i ">
                        {{i+1}}. {{item}}
                    </li>
                    </ul>
                 </td> -->
                <td>{{clientproject.startDate}}</td>
                <td>{{clientproject.endDate}}</td>
                <td>{{clientproject.total_no}}</td>
                <td>{{clientproject.total_hour_worked}}</td>
                <td>
                    
                    <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-book" class="p-button-outlined"
                    (click)="selectClientprojectDetail(clientproject.idClient_project,'projectdetail')"></button>
                
                </td>
                
                
            </tr>
        </ng-template>
    </p-table>    
</div>