import { Component, OnInit, ViewChild } from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {IncidenciaComponent } from '../incidencia/incidencia.component';
import {CBLService} from '../servicios/cbl.service'
import { HttpClient,HttpHeaders,HttpParams,HttpResponse } from '@angular/common/http';
import { Respuesta } from '../libs/respuesta';
import {formatDate} from '@angular/common';
import {Solicitud,Documento} from '../libs/dataObjects'
import {MessageService} from 'primeng/api';
import {FileUpload} from 'primeng/fileupload';
import { Dropdown } from "primeng/dropdown";

export interface TiposIncidencia {
  nombre: string;
  id: string;
}

export class Incidencia {
  estado: string;
  fechaInicio: Date;
  fechaFin: Date;
  observaciones: string;
  tipoIncidencia: number;
  adjuntos: any[] = [];
  tipo: string;
  motivo: string;
}

@Component({
  selector: 'app-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.scss'],
})
export class IncidenciasComponent implements OnInit {
  @ViewChild('dropDownThing')  dropDownThing: Dropdown;
  tiposIncidencias: TiposIncidencia[];
  selectedTipoIncidencia: TiposIncidencia;
  selectedIncidencia: Incidencia = null;
  tempIncidencia: Incidencia = new Incidencia();
  collapsed: boolean = true;
  yearArray:any[] = [];
  selectedYear:any;
  message = "Documentos del año";
  currentSearchYear:any;
  es: any;
  dateFrom: Date;
  dateTo: Date;
  incidenciasTemp =[]
  incidencias: Incidencia[] = [
    {
      estado: 'Pendiente',
      tipo: 'Asistencia al médico',
      fechaInicio: new Date(),
      fechaFin: null,
      observaciones: 'Se encuentra en estado pendiente en el lugar.',
      tipoIncidencia: 1,
      adjuntos: [
        { name: 'Cita previa en clínica estomatológica', size: '30.000' },
      ],
      motivo: '',
    },
    {
      estado: 'Rechazada',
      tipo: 'Olvido de fichaje',
      fechaInicio: new Date(),
      fechaFin: new Date(),
      observaciones: '',
      tipoIncidencia: 2,
      adjuntos: [],
      motivo:
        'Falta de documentación acreditativa sobre el motivo de la ausencia',
    },
  ];

  uploadedFiles: any[] = [];

  constructor(public cbl:CBLService,private messageService: MessageService,private httpClient: HttpClient,public dialogService: DialogService) {
    this.tiposIncidencias = [
      { nombre: 'Asistencia al médico', id: '1' },
      { nombre: 'Olvido de fichaje', id: '2' },
    ];
  }

  ngOnInit(): void {
    this.createYearPicker();
    this. loadIncidencias();

    this.es = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      clear: 'Borrar',
    };
  }

  onUpload(event) {
    for (let file of event.files) {
      this.tempIncidencia.adjuntos.push(file);
    }
  }

  onRowSelect(event) {
    this.collapsed = false;
    this.tempIncidencia = this.selectedIncidencia;
    this.selectedTipoIncidencia = this.tiposIncidencias[
      this.tempIncidencia.tipoIncidencia - 1
    ];
  }
  onRowUnselect(event) {
    this.collapsed = true;
    this.tempIncidencia = new Incidencia();
    this.selectedIncidencia = null;
    this.selectedTipoIncidencia = null;
  }

  crearIncidencia(event) {
    this.collapsed = true;
  }

  selectIncidencia(id){
    const ref = this.dialogService.open(IncidenciaComponent, {
      data:{id:id},
      header: 'Detalle incidencia',
      width: '70%'
  });

  }

  nueva()
  {
    const ref = this.dialogService.open(IncidenciaComponent, {
      data:{id:0},
      header: 'Detalle incidencia',
      width: '70%'
      });

    ref.onClose.subscribe((ret) => {
      this.selectedYear = null
      if(this.dropDownThing != undefined && this.dropDownThing != null)
        this.dropDownThing.updateSelectedOption(null);
      this.loadIncidencias();
     
    });
    
  }

  loadIncidencias()
  {
    let url2='solicitudes?'
    +'&fields=idsolicitud,solicitud,fechainicio,obs,fechafin,horainicio,horafin,fechas,estado'
    +'&where=idEmpleado='+this.cbl.id_empleado+" and idTipoPermiso=0"
    +'&order=fechainicio DESC';
    //+'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado';

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      console.log(r);
      for(let p of r.resp)
      {
          let dias=0;
          //for(let d of p.permisos)
          //{
            let cd=p.fechas.split(",").length;
            p['dias']=cd; 
            if (cd>1)
              p['periodo']=formatDate(p.fechainicio,'dd/MM/yyyy','en-US')+" - "+formatDate(p.fechafin,'dd/MM/yyyy','en-US');
            else
              p['periodo']=p.fechainicio;

            if(p.horainicio!=null && p.horafin!=null)
            {
              p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
            }
            if(p.estado!="RECHAZADA") dias+=cd;
          //}
          p['dias']=dias;
      }
      console.log(r.resp);
      this.incidencias=r.resp;
      this.incidenciasTemp=r.resp;
      this.searchBasedOnYear()
    });
  }
  createYearPicker(){
    let currentYear = new Date().getFullYear();
    let earliestYear = 2010;

    while (currentYear >= earliestYear) {
     this.yearArray.push({"year":currentYear})
      currentYear -= 1;
    }
  }
 
  searchBasedOnYear(){
    let tempData  = [];
    console.log(this.selectedYear)
    if(this.selectedYear != null && this.selectedYear !== undefined){
        this.currentSearchYear = ""+this.selectedYear["year"]
        this.incidenciasTemp.forEach(value =>{
          let yearFromDb:string = ""+value["periodo"];
          if(yearFromDb.indexOf(this.currentSearchYear)>-1)  {
            tempData.push(value);
          }       
        });         
    }else{
      this.currentSearchYear  = ''
      this.incidenciasTemp.forEach(value =>{
        tempData.push(value);
      });
    }
    this.incidencias = tempData;
  }
}
