import { Component, OnInit } from '@angular/core';
import {TabMenuModule} from 'primeng/tabmenu';
import {MenuItem} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {FichajeComponent } from '../fichaje/fichaje.component';
import {CBLService} from '../servicios/cbl.service'

@Component({
  selector: 'app-jornada',
  templateUrl: './jornada.component.html',
  styleUrls: ['./jornada.component.scss'],
})
export class JornadaComponent implements OnInit {
  dateSelector: Date=new Date("06/01/2020"); 
  //es: any;

  jornadas: any[] = [
    { fecha: '30/06/2020', saldo: '08:30', situacion: '' },
    { fecha: '29/06/2020', saldo: '08:00', situacion: 'Vacaciones' },
    { fecha: '26/06/2020', saldo: '09:15', situacion: '' },
    { fecha: '25/06/2020', saldo: '08:35', situacion: '' },
  ];

  dateConfig:any;

  constructor(public dialogService: DialogService,public cbl:CBLService) {
    this.dateConfig=this.cbl.dateConfig;
  }

  items: MenuItem[];

  ngOnInit(): void {
    this.items = [
      {label: 'Fichajes', icon: 'fas fa-clock',routerLink: ['fichar']},
      {label: 'Jornadas mes', icon: 'pi pi-fw pi-calendar',routerLink: ['visitas']},
      {label: 'Visitas', icon: 'pi pi-fw pi-calendar',routerLink: ['visitas']}
  ];

  //   this.es = {
  //     firstDayOfWeek: 1,
  //     dayNames: [
  //       'Domingo',
  //       'Lunes',
  //       'Martes',
  //       'Miércoles',
  //       'Jueves',
  //       'Viernes',
  //       'Sábado',
  //     ],
  //     dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
  //     dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
  //     monthNames: [
  //       'Enero',
  //       'Febrero',
  //       'Marzo',
  //       'Abril',
  //       'Mayo',
  //       'Junio',
  //       'Julio',
  //       'Agosto',
  //       'Septiembre',
  //       'Octubre',
  //       'Noviembre',
  //       'Diciembre',
  //     ],
  //     monthNamesShort: [
  //       'Ene',
  //       'Feb',
  //       'Mar',
  //       'Abr',
  //       'May',
  //       'Jun',
  //       'Jul',
  //       'Ago',
  //       'Sep',
  //       'Oct',
  //       'Nov',
  //       'Dic',
  //     ],
  //     today: 'Hoy',
  //     clear: 'Borrar',
  //   };

}

selectJornada(fecha){
  const ref = this.dialogService.open(FichajeComponent, {
    data:{readOnly:true},
    header: 'Fichajes del '+fecha,
    width: '70%'
});
}


}
