import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';

export interface Notificacion {
  id: string;
  mensaje: string;
  fecha: Date;
  read: boolean;
}

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.scss'],
  providers: [MessageService],
})
export class NotificacionComponent implements OnInit {
  notificaciones: Notificacion[] = [];

  constructor(private messageService: MessageService) {
    this.notificaciones = [
      {
        id: '1',
        mensaje: 'Petición de vacaciones ACEPTADA',
        fecha: new Date(),
        read: true,
      },
      {
        id: '2',
        mensaje: 'Incidencia RECHAZADA',
        fecha: new Date(),
        read: false,
      },
      {
        id: '3',
        mensaje: 'Petición de vacaciones RECHAZADA',
        fecha: new Date(),
        read: true,
      },
    ];
  }
  ngOnInit(): void {}
}
