<!--<h3 class="title">Mis documentos</h3>-->


<div class="grid r-grid r-grid12-6" style="width:'100%';">




    <div class="g-w12field">
    <div class="documentos-header">
        <div class="center" style=" flex:30;">
            <h3 class="title">Mis documentos</h3>
        </div>
        <div class="right">
            <div style=" display:flex;  flex-direction: row;">
                <p-dropdown
                    [options]="yearArray"
                    placeholder="Seleccionar año"
                    optionLabel="year"
                    [showClear]="true"
                    [(ngModel)]="selectedYear"
                    [style]="{'font-size':'10px'}"
                    #dropDownThing
                >
                </p-dropdown>
                <button class="button" (click)="searchBasedOnYear()" style="margin-left: 4px; cursor: pointer;">Buscar</button>
            </div>
        </div>
    </div>
</div>







    <div class="g-w12field">
        <p-tree  selectionMode="single" (onNodeSelect)="cbl.viewTreeDoc($event)" [value]="arbol" styleClass="arbol-docs22"  [style]="{width: '100%'}">
                
            <ng-template let-node pTemplate="rama">
                {{node.label}} 
            </ng-template>
            
            <ng-template let-node pTemplate="doc" >
                <p>{{node.label}} </p>
            </ng-template>
            
        </p-tree>
        <br>
            <br>
            <br>
            <br>
            <br>
    </div>
</div>