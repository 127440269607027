import {Component, OnInit,Input,Output, EventEmitter, ViewChild } from '@angular/core';
import {Visita} from '../../libs/dataObjects'
import {CBLService} from '../../servicios/cbl.service'
import {MessageService} from 'primeng/api';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-visita',
  templateUrl: './visita.component.html',
  styleUrls: ['./visita.component.scss']
})
export class VisitaComponent implements OnInit {

  id_visita:number=0;
  dateConfig:any;
  readOnly:boolean=false;
  display: boolean = false;
  visita:Visita;
  visitasAttachment:any[] = []
  visitasExistingAttachment:any[] = []

  @ViewChild('fileUpload') fileUpload: any;
  @ViewChild('fileUpload2') fileUpload2: any;

  @Output() nueva_visita = new EventEmitter<Visita>();

  constructor(public cbl:CBLService,private messageService: MessageService) {
    this.dateConfig=this.cbl.dateConfig;
    this.visita=new Visita();
  }

  ngOnInit(): void {
    this.clear()
  }

  registrarInicio() {this.visita.inicio=new Date();}
  registrarFin()    {this.visita.fin=new Date();}

  public show(id,type)
  {
    if (id)
    {
      this.readOnly=false;
      this.id_visita = id;
      this.visitasExistingAttachment = []
      this.cbl.loadVisita(this.id_visita).then((v)=>{
        this.visita=v;
        this.visita['inicio']=new Date(formatDate(this.visita.inicio,'yyyy-MM-dd H:mm','en-US'));
		    if(this.visita.fin != null)
			     this.visita['fin']=new Date(formatDate(this.visita.fin,'yyyy-MM-dd H:mm','en-US'));
        this.visita.idHorario = this.id_visita
        if(type == 'view'){
         this.readOnly=true;
        }
        this.cbl.getVisitasAttachmentById(this.id_visita).then((res:any[])=>{
          console.log(res)
           if(res != null && res.length>0){
              this.visitasExistingAttachment = res
           }
        })
      });
    }
    else
    {
      this.id_visita = 0;
      this.visita=new Visita();
      this.visita.idEmpleado=this.cbl.id_empleado;
      this.readOnly=false;
    }
    this.display=true;
  }

  async guardar()
  {
    //this.visita['attachment'] =  this.visitasAttachment
    console.log(this.visita)
    if(this.id_visita==0)
    {
      if(this.visita.inicio==null)
      {
          this.messageService.add({severity: 'warn', summary: 'Aviso', detail: 'Inicio es campo obligatorio!'});
      }
      else
      {
        this.cbl.insertVisita(this.visita).then((r)=>{
          this.visita.idHorario=r.resp['idHorario'];
          this.saveAttachment(this.visita.idHorario)
        });
      }
    }else{
      this.cbl.updateVisita(this.id_visita,this.visita).then((r)=>{  
        this.saveAttachment(this.id_visita,)
      }).catch(err=>{
        this.saveAttachment(this.id_visita,);
      });
    }
  }

  saveAttachment(id){
    if(this.visitasAttachment.length>0){
      this.cbl.insertorupdateVisitasAttachment(id, this.visitasAttachment).then(res=>{
          this.clear()
         this.nueva_visita.emit(this.visita);
         this.display=false;
      })
     }else{
      this.clear()
       this.nueva_visita.emit(this.visita);
       this.display=false;
     }
  }

  cancelar()
  {
    this.clear()
    this.display=false;
  }
  onBasicUpload(event,index){{
      for (const file of event.currentFiles) {
        this.readFile(file, index);
      }
    }
  }
  
   readFile(file: File, index) {
      let data:any = {
        idvisitasattachment:this.visitasExistingAttachment[index]?this.visitasExistingAttachment[index]['idvisitasattachment'] :0,
        filename:file['name'],
        filetype:file['type']
      }
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let readerData:any  = reader.result
        let readerDataArray:any[] = readerData.split(',');
        data['filedata'] = JSON.stringify(readerDataArray[0]);
        data['other'] = JSON.stringify(readerDataArray[1]);
      };
      this.visitasAttachment[index] = data;
    }

    removeFile(index){
      this.visitasAttachment[index] = null;
    }

    clear() {
      if(this.fileUpload !== undefined&& this.fileUpload !== null)
        this.fileUpload.clear();
      if(this.fileUpload2 !== undefined&& this.fileUpload2 !== null)
        this.fileUpload2.clear();
    }

    showDocument(data){
        this.cbl.viewSolicitudAttachment(data.filepath+"/"+data.filename).then(r=>{    
         let filedata:string = data.filedata
         console.log(typeof filedata)
         filedata = filedata.substring(1, filedata.length - 1)   
         console.log(filedata)
          const linkSource =  filedata+","+r[0]['data'];
          console.log(linkSource)
          const downloadLink = document.createElement("a");
          const fileName = data.filename;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
    }
}
