import { Component, OnInit, ViewChild } from '@angular/core';
import {TabMenuModule} from 'primeng/tabmenu';
import {MenuItem} from 'primeng/api';
import { CBLService } from '../servicios/cbl.service';
import { VistasDateService } from '../servicios/vistas-date.service';
import { Dropdown } from "primeng/dropdown";
import { HttpClient } from '@angular/common/http';
//import { Respuesta } from '../src/app/respuesta';
import { Respuesta } from '../libs/respuesta';
import {DialogService} from 'primeng/dynamicdialog';
import { ClientprojectdetailmainComponent } from '../clientprojectdetailmain/clientprojectdetailmain.component';

@Component({
  selector: 'app-jornadas-main',
  templateUrl: './jornadas-main.component.html',
  styleUrls: ['./jornadas-main.component.scss']
})
export class JornadasMainComponent implements OnInit {
  @ViewChild('dropDownThing')  dropDownThing: Dropdown;
  dateSelector: Date;
  es: any;
  employeeName : any = ''; 
  companyName:any = '';
  currentDate:any
  selectedYear:any
  currentSelectedYear:any
  currentYear:any
  yearArray:any[] = [];
  currentSearchYear:any;
  currentActiveTab = 'Fichajes';



  jornadas: any[] = [
    { fecha: '30/06/2020', saldo: '8h 30m', situacion: '' },
    { fecha: '29/06/2020', saldo: '8h', situacion: 'Vacaciones' },
    { fecha: '26/06/2020', saldo: '9h 15m', situacion: 'Reunión' },
    { fecha: '25/06/2020', saldo: '8h 35m', situacion: '' },
  ];

  constructor(private userService:CBLService, private selecteYearService:VistasDateService,private httpClient: HttpClient,public gds:CBLService,public cbl:CBLService,public dialogService: DialogService) {
    this.employeeName = this.userService.getEmployeeName();
    this.userService._get_clientes().then(res=>{
      let data: any[] = res['resp'];
      if(data != null && data.length>0){
         this.companyName =data[0]["Cliente"] 
      }
    })
  }

  items: MenuItem[];
  item: MenuItem;;

  clientprojects =[] ;
  selectedClientproject = null;

  ngOnInit(): void 
  {
    this.items = [
      {label: 'Fichajes', icon: 'fas fa-clock',routerLink: ['fichar'],command: (event) => {
        this.item=event['item']
        this.currentActiveTab = event['item']['label']
      }},
      //{label: 'Jornadas mes', icon: 'pi pi-fw pi-calendar',routerLink: ['saldos']},
      {label: 'Visitas', icon: 'pi pi-fw pi-calendar',routerLink: ['visitas'],command: (event) => {
        this.item=event['item']
         this.currentActiveTab = event['item']['label']
         this.resetSearchYear()
       }},

       {label: 'Historial', icon: 'pi pi-fw pi-calendar',routerLink: ['history'],command: (event) => {
         this.item=event['item']
         this.currentActiveTab = event['item']['label']
         this.resetSearchYear()
       }}
    ];

    this.es = {
      firstDayOfWeek: 1,
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
      dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      clear: 'Borrar',
    };

    this.currentDate = this.formatDate();
    this.createYearPicker()
    this.resetYear();

    this.loadClientprojects();
  }

 formatDate() {
    let d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    this.currentSelectedYear = year
    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

createYearPicker(){
  this.currentYear = new Date().getFullYear();
  let tempYear =  this.currentYear;
  let earliestYear = 2010;

  while (tempYear >= earliestYear) {
   this.yearArray.push({"year":tempYear})
   tempYear -= 1;
  }
}

searchBasedOnYear(){
  this.selecteYearService.setSelectedYear(this.selectedYear)
}
  resetYear(){
    this.selecteYearService.getSelectedYear().subscribe(data=>{
      if(data != null){
        this.currentSelectedYear = ""+data['year']
      }else{
        if(this.dropDownThing != undefined && this.dropDownThing != null)
          this.dropDownThing.updateSelectedOption(null)
        this.currentSelectedYear = this.currentYear
      }
    })
  }

  resetSearchYear(){
    this.selecteYearService.clearSelectedYear();
    if(this.dropDownThing != undefined && this.dropDownThing != null)
      this.dropDownThing.updateSelectedOption(null); 
  }




















  loadClientprojects()
  {
    // let url2='solicitudes?'
    // +'&fields=idsolicitud,solicitud,fechainicio,obs,fechafin,horainicio,horafin,fechas,estado'
    // +'&where=idEmpleado='+this.cbl.id_empleado+" and idTipoPermiso=0"
    // +'&order=fechainicio DESC';

    // let url2='complaint?'
    // +'&fields=idComplaint,idEmpleado,complaintDate,complaintPrivacy,complaintType,complaintAttachDocument,complaintDesc,complaintStatus,complaintCommunication'
    // +'&where=idEmpleado='+this.cbl.id_empleado+" "
    // +'&order=idComplaint DESC'; 


    //console.log("xxxxxxxxxxxxxx",this.gds);
    let cond='';
    if(this.gds.id_empleado != null ){

      cond+=' and employees LIKE "@@@,'+this.gds.id_empleado+',@@@" ';
    }


    let url2='clientproject/client_project?'
    +'&fields=idClient_project,clientId,project_name,employees,startDate,endDate,total_no'
    +'&where=clientId='+this.gds.id_cliente+" "+cond+"  "
    +'&order=idClient_project DESC'; 

    //+'&tree=solicitud->permisos=>fechainicio,fechafin,horainicio,horafin,fechas,estado';

    this.httpClient.get<Respuesta>(this.gds.urlBaseDL+url2).toPromise().then(async (r)=>{
      console.log(r);
      
      for(let p of r.resp)
      {
          let dias=0;
          // for(let d of p.permisos)
          // {
            // let cd=p.fechas.split(",").length;
            // p['dias']=cd; 
            // if (cd>1)
             // p['stampDate']=formatDate(p.stampDate,'dd/MM/yyyy','en-US');
            // else
            //   p['periodo']=p.fechainicio;

            // if(p.horainicio!=null && p.horafin!=null)
            // {
            //   p['periodo']+=" "+p.horainicio.substr(0,5)+"-"+p.horafin.substr(0,5);
            // }
            // if(p.estado!="RECHAZADA") dias+=cd;

           // p['periodo']=formatDate(p.complaintDate,'dd/MM/yyyy','en-US')+" - "+formatDate(p.complaintDate,'dd/MM/yyyy','en-US');

           // p['periodo']=p.complaintDate;
          // }
          // let respEmp=this.getEmployeeName(p.idEmpleado);
          // console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
          //  p['dias']=dias;
           p['clientName']=await this.getClientName(p.clientId);
          

           

         // let respEmp=this.getEmployeeName(p.idEmpleado);
          //console.log("qqqqqqqqqqqqqqqqqqq",respEmp);
          //  p['dias']=dias;

            var employees=p.employees;
            if (employees.charAt(employees.length - 1) === ',') {
              employees = employees.slice(0, -1);
            }
            var employees = (employees[0] == ',') ? employees.substr(1) : employees;

            let employeesArr=employees.split(",");

            var allEmp="";
            var allEmpArr=[];
            for (var index in employeesArr) {
            let idEmpleado=employeesArr[index];
            //let idEmpleado ='13801';
            allEmp+=await this.getEmployeeName(idEmpleado)+"<br>";
            allEmpArr[index]=await this.getEmployeeName(idEmpleado);

            }
          //  03:47:50
            console.log("xxooppp",allEmpArr);
            //p['employeeName']=allEmpArr;
            p['employeeName']=await this.getEmployeeName(this.gds.id_empleado);

           // p['total_hour_worked']= await this.getTotalHourWorked(employees,p.idClient_project);


            var itemHourTemp=await this.getTotalHourWorked(employees,p.idClient_project);
           p['total_hour_worked']= this.secondsToTime(itemHourTemp);
            // p['employeeName']=await this.getEmployeeName(idEmpleado);
          // if(p.complaintHandledClientId!='0')
          // {
          //   //p['complaintInCharge']=await this.getClientName(p.complaintHandledClientId);
          //   p['complaintInCharge']=await this.getPersenInChargeName(p.complaintHandledClientId);
          // }
          // else{
          //   p['complaintInCharge']="";
          // }
           


           
           //this.getEmployeeName(p.idEmpleado);
          //r.resp.push(p);
         // r.resp.push(m);
      }
      console.log("sssssssssssssssssssss",r.resp);
      this.clientprojects=r.resp;
      //this.clientprojectsTemp=r.resp;
      //this.searchBasedOnYear();
     // this.searchBasedOnProject();
    });
  }



  async getAllProject(){

    //alert(this.gds.id_cliente);
    let url2='client_project?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idClient_project,project_name'
    +'&where=clientId='+this.gds.id_cliente+' '
    //+'&where=E.idCliente='+this.gds.id_cliente+''
    //+'&order=E.idEmpleado DESC';  //&compacto=true
    //+ '&groupby=E.idEmpleado'
    + '&order=project_name ASC'; 

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp;
      //this.nominasTemp = r['resp'];
     // console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      console.log("xxxxxxgogoxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }



  async getEmployeeName(id){
    let url2='empleado?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=E.idEmpleado as idEmpleado,T.empleado as empleado'
    +'&where=E.idEmpleado ='+id+' '
    +'&order=E.idEmpleado DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.gds.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['empleado'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/ 
    }); 

   return respuesta;
  }
  async getClientName(id){
    let url2='clientes?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=idCliente,Cliente'
    +'&where=idCliente='+id+' '
    +'&order=idCliente DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      let resp=r['resp'];
      respuesta=resp[0]['Cliente'];
      //this.nominasTemp = r['resp'];
      console.log("xxxxxxxxxxxxxxxxxxxx.....",respuesta)
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/
    }); 

   return respuesta;
  }


  
  pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }
 

  async getTotalHourWorked(employees,idClient_project){
    let url2='client_project_employee_horario?' //&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    //+'&joins=trabajador(E.idTrabajador=>T.idTrabajador)'
    +'&fields=sum(hour) as hour,sum(minute) as minute,sum(second) as second'
    +'&where=idClient_project='+idClient_project+'  and idEmpleado IN('+employees+')  '
    +'&order=idClient_project_employee_horario DESC';  //&compacto=true

    //let resp= this.httpClient.get<Object[]>(this.cbl.urlBaseDL+url2).toPromise();

   // return  this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise();

    //console.log("xxxxxxxxxxxxxxxxxxxx.....",resp)
    var respuesta;
    //var hour;
    //var minute;
    //var second;
   var TotalTime=0;
    await this.httpClient.get(this.cbl.urlBaseDL+url2).toPromise().then((r)=>{
      
      let resp=r['resp'];
      if(resp)
      {
      console.log("xxxxxxxxxxxxxgogogxxxxxxx.....",resp)
      
      var hour=resp[0]['hour'];
      var minute=resp[0]['minute'];
      var second=resp[0]['second'];

      if(hour=='' || hour==null)
      {
       hour='00';
      }
   
      if(minute=='' || minute==null)
      {
       minute='00';
      }
   
      if(second=='' || second==null)
      {
       second='00';
      }
   
    
   var hour = this.pad(hour, 2,'');

  
   var minute = this.pad(minute, 2,'');


   var second = this.pad(second, 2,'');
   

 

    var itemTime=hour+":"+minute+":"+second;
    var at = itemTime.split(':'); 
    var timeInSec = (+at[0]) * 60 * 60 + (+at[1]) * 60 + (+at[2]); 
      
      //this.nominasTemp = r['resp'];
      
      /*this.total=0.0;
      this.nominas=this.nominas.map((nom)=>{
         if (nom.nominas[0][0]==null) nom.nominas=[]; else this.total+=nom.importe;
         return nom;
      });
      console.log(this.nominas,this.total);*/

      TotalTime+=TotalTime+timeInSec;


    }
    }); 

    

   return TotalTime;
  }

  secondsToTime(e){
    const h = Math.floor(e / 3600).toString().padStart(2,'0'),
          m = Math.floor(e % 3600 / 60).toString().padStart(2,'0'),
          s = Math.floor(e % 60).toString().padStart(2,'0');
    
    return h + ':' + m + ':' + s;
    //return `${h}:${m}:${s}`;
  }
  

  onUpload(event) {
    for (let file of event.files) {
      //this.tempClientproject.adjuntos.push(file);
    }
  }

  onRowSelect(event) {
    //this.collapsed = false;
    //this.tempClientproject = this.selectedClientproject;
    // this.selectedTipoClientcollaborator = this.tiposClientcollaborators[
    //   this.tempClientcollaborator.estado - 1
    // ];
  }
  onRowUnselect(event) { 
    //this.collapsed = true;
   // this.tempClientproject = new Clientproject();
    //this.selectedClientproject = null;
    //this.selectedTipoClientproject = null;
  }

  crearClientproject(event) {
    //this.collapsed = true;
  }




  selectClientprojectDetail(id,pagetype){
    const ref = this.dialogService.open(ClientprojectdetailmainComponent, {
      data:{id:id,pagetype:pagetype},
      header: 'Detalles ', 
      width: '70%'
      });

      ref.onClose.subscribe((ret) => {

        this.loadClientprojects();
        this.selectedYear = null
        if(this.dropDownThing != undefined && this.dropDownThing != null)
          this.dropDownThing.updateSelectedOption(null);
        this.loadClientprojects();
       
      });

  }




















}
