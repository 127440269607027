import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CBLService } from '../servicios/cbl.service'
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Respuesta } from '../libs/respuesta';
import { formatDate } from '@angular/common';
import { Solicitud, Documento } from '../libs/dataObjects'
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-incidencia', 
  templateUrl: './incidencia.component.html',
  styleUrls: ['./incidencia.component.scss'],
})
export class IncidenciaComponent implements OnInit {

  dateConfig: any;
  incidencia: Solicitud = new Solicitud();
  uploadedFiles: any[] = [];
  readonly: boolean = false;
  remarkBody = {
    comment: null,
    file1: null,
    file2: null,
    file3: null
  };
  _pdf: SafeUrl;


  @ViewChild('envioDoc') envioDoc: FileUpload;
  @ViewChild('fileUpload') fileUpload: any;
  @ViewChild('fileUpload2') fileUpload2: any;
  @ViewChild('fileUpload3') fileUpload3: any;
  history: Respuesta;

  constructor(public cbl: CBLService, private messageService: MessageService,
    private httpClient: HttpClient, public dialogService: DialogService, public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public sanitizer: DomSanitizer) {

    let id = this.config.data.id;
    console.log(id);

    if (id) {
      //id=18;
      this.loadIncidencia(id); 
      this.getIncendias(id);
      this.readonly = true;
    }
    else {
      this.incidencia = new Solicitud();
      this.incidencia.fechainicio = new Date();
      this.readonly = false;
    }

    this.dateConfig = this.cbl.dateConfig;

  }

  ngOnInit(): void {

  }

  async solicitar() {
    if (this.incidencia.solicitud == "") {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Incidencia es obligatorio!' });
      return;
    }
    if (this.incidencia.fechainicio == undefined || this.incidencia.fechainicio == null) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El campo Fecha es obligatorio!' });
      return;
    }
    if (this.incidencia.horainicio != undefined || this.incidencia.horafin != undefined) {
      if (this.incidencia.horainicio > this.incidencia.horafin || this.incidencia.horainicio == undefined || this.incidencia.horafin == undefined) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Hora de fin debe ser mayor que hora de inicio!' });
        return;
      }
    }

    console.log(this.envioDoc);
    if (this.envioDoc.files.length > 0) {
      let meta = new Documento({
        idEmpleado: this.cbl.id_empleado,
        idCliente: this.cbl.id_cliente,
        descripcion: this.envioDoc.files[0].name + " (" + this.cbl.empleado + ")",
        idMateria: 5, idSubMateria: 36
      });
      await this.cbl.send_documentos(this.envioDoc.files, meta).then((r) => {
        console.log(r);
        this.incidencia.doc = r[0];
      });
    }


    this.incidencia.fechafin = this.incidencia.fechainicio;
    this.incidencia.estado = 'SOLICITUD';
    this.incidencia.idEmpleado = this.cbl.id_empleado;
    this.cbl.insertSolicitud(this.incidencia).then()
    {
      this.cbl.emailToCliente(this.cbl.id_cliente, "Ha recibido una nueva solicitud en el Portal del Cliente", `Estimado ${this.cbl.name_cliente}:\n\nUn empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de incidencia << ${this.incidencia.solicitud} >>.\n\nSaludos`);

      

      // this.cbl.emailToDepartmentFromEmployee(this.cbl.id_empleado,"Ha recibido una nueva solicitud en el Portal del Cliente", `Estimado responsable (${this.cbl.name_cliente}):\n\nUn empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de incidencia << ${this.incidencia.solicitud} >>.\n\nSaludos`);

      this.cbl.emailToDepartmentFromEmployee(this.cbl.id_empleado,"Ha recibido una nueva solicitud en el Portal del Cliente", `Estimado responsable (##DEPARTMENT_NAME##):\n\nUn empleado (${this.cbl.empleado}) acaba de remitirle una nueva solicitud de incidencia << ${this.incidencia.solicitud} >>.\n\nSaludos`);

      this.ref.close(true);


      




    }
  }

  cerrar() {
    this.ref.destroy(); 
  }

  loadIncidencia(id) {
    let url2 = 'solicitudes?'
      + "&fields=idsolicitud,solicitud,fechainicio,fechainicio,horainicio,horafin,estado,doc,obs"
      + '&where=idSolicitud=' + id;

    this.httpClient.get<Respuesta>(this.cbl.urlBaseDL + url2).toPromise().then((r) => {
      console.log(r.resp);
      r.resp[0].horainicio = r.resp[0].horainicio.substr(0, 5);
      r.resp[0].horafin = r.resp[0].horafin.substr(0, 5);
      r.resp[0].fechainicio = formatDate(r.resp[0].fechainicio, 'dd/MM/yyyy', 'en-US');
      this.incidencia = new Solicitud(r.resp[0]);
      this.incidencia.fechafin = this.incidencia.fechainicio;
    });
  }

  /**functions to cpature comments and upload files start */
  onBasicUpload(inputData, key) {
    for (const file of inputData.currentFiles) {
      const dataset = this.readFile(file, key);
    }
  }

  private readFile(file: File, key) {
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.remarkBody[key] = JSON.stringify(reader.result);
    };
  }

  saveComment() {
    this.httpClient.post<Respuesta>(this.cbl.urlBaseDL + 'incidenciaremark/' + this.config.data.id, this.remarkBody).toPromise().then((r) => {
      if (r) {
        this.remarkBody.comment = null;
        this.remarkBody.file1 = null;
        this.remarkBody.file2 = null;
        this.remarkBody.file3 = null;
        this.getIncendias(this.config.data.id);
        this.clear();
      }
    });
  }
  /**functions to cpature comments and upload files end */
  getIncendias(id) {
    this.httpClient.post<Respuesta>(this.cbl.urlBaseDL + 'getincidenciaremark/' + id, {}).toPromise().then((r) => {
      this.history = r;
    });
  }

  showpdf(file) {
    let fileArray:any[] = file.split(",");
      const [,type] = fileArray[0].split(';')[0].split('/');
      const linkSource = file;
      const downloadLink = document.createElement("a");
      const fileName = "document."+type;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
  }

  clear() {
    this.fileUpload.clear();
    this.fileUpload2.clear();
    this.fileUpload3.clear();
  }
}
