
<div class="incidencias-header">
    <div class="center">
        <h3 class="title">Incidencias</h3>
    </div>
    <div class="right">
        <div style=" display:flex;  flex-direction: row;">
            <p-dropdown
                [options]="yearArray"
                placeholder="Seleccionar año"
                optionLabel="year"
                [showClear]="true"
                [(ngModel)]="selectedYear"
                [style]="{'font-size':'10px'}"
                #dropDownThing
            >
            </p-dropdown>
            <button class="button" (click)="searchBasedOnYear()" style="margin-left: 4px; cursor: pointer;">Buscar</button>
        </div>
    </div>
</div>
<div class="example-column">
    <p-table [value]="incidencias" selectionMode="single" [(selection)]="selectedIncidencia"
        [rowsPerPageOptions]="[5,10,20,30]" [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
        currentPageReportTemplate="Mostrando {first} de {totalPages}" (onRowSelect)="onRowSelect($event)"
        (onRowUnselect)="onRowUnselect($event)">
        <ng-template pTemplate="header">
            <tr>
                <th>Estado</th>
                <th>Fecha</th>
                <th>Incidencia</th>
                <th>Observaciones</th>
                <th width="10%"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-incidencia>
            <tr [pSelectableRow]="incidencia">
                <td>{{incidencia.estado}}</td>
                <td>{{incidencia.periodo}} </td>
                <td>{{incidencia.solicitud}}</td>
                <td>{{incidencia.obs}}</td>
                <td>
                    <button title='Ver detalle' pButton pRipple type="button" icon="fas fa-eye" class="p-button-outlined"
                    (click)="selectIncidencia(incidencia.idsolicitud)"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>    
</div>

<div class="grid r-grid r-grid12-6" style="width:'100%';">
    <div class="g-w12field">
        <div class="f-box f-jcsb" style="margin-top:5px">
            <div class="f-w1"><p-button label="Nueva incidencia" (click)="nueva()" icon="fas fa-paper-plane" iconPos="left"></p-button></div>
            <br>
            <br>
            <br>
            <br>
            <br>
        </div>
    </div>
</div>
